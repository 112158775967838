import React from "react";

import {
    Box,
    Grid,
    Card, CardContent,
    Typography,
    Tooltip,
    styled
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import "../../dashboard/src/dashboard.css"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { Leaderboard, Scoreboard, Infoboard, leader_icon, Polygon, Medallion,pngegg } from './assets';


// Customizable Area End

import AddKpiController, { Props } from "./AddKpiController";
import { user_profile } from "../../CustomisableUserProfiles/src/assets";
import { oval } from "../../dashboard/src/assets";

export default class Statistics extends AddKpiController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={webStyles.maincontainer}>
                <Box style={webStyles.headerSection}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid style={webStyles.leftContainer} item>

                            <ArrowBackIosIcon data-test-id="goback" onClick={() => this.Goback("EmployeeDashboard")} style={{ fontSize: '25px', marginRight: '20px' }} />
                            <span data-test-id="gobackhome" style={webStyles.labelStyle1} onClick={() => this.Goback("EmployeeDashboard")}>Home</span>
                            <ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                            <span style={webStyles.labelTwoStyle1} onClick={() => this.setState({ statisticsTab: "" })}>Statistics</span>
                            {this.state.statisticsTab && <><ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                                <span style={webStyles.labelTwoStyle1}>{this.state.statisticsTab}</span></>}
                        </Grid>
                    </Grid>
                </Box>

                <Grid container className='stepperContainer' spacing={2}>
                    <Grid
                        item
                        alignItems="center"
                        lg={12}
                    >
                        {this.state.statisticsTab == "" && <div style={{ display: "flex", marginTop: '70px' }}> <Grid alignItems="center" item>
                            <Card data-test-id="AddKpiReport" onClick={() => this.setState({ statisticsTab: "Leaderboard" })} style={webStyles.root as React.CSSProperties} variant="outlined">
                                <CardContent>
                                    <img src={Leaderboard} alt="logo" width={100} height={114} />
                                    <Typography variant="body2" style={{ color: 'black' }} component="p">
                                        Leaderboard
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                            <Grid alignItems="center" item>
                                <Card data-test-id="KpiGameCard" onClick={() => this.setState({ statisticsTab: "Scoreboard", scoreTabNumber: 0 })} style={webStyles.root as React.CSSProperties} variant="outlined">
                                    <CardContent style={{ cursor: "pointer" }}>
                                        <img src={Scoreboard} alt="logo" width={100} height={105} />
                                        <Typography
                                            variant="body2" style={{ color: 'black' }} component="p">
                                            Scoreboard
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid alignItems="center" item>
                                <Card data-test-id="Statistics" onClick={() => this.setState({ statisticsTab: "Infoboard" })} style={webStyles.root as React.CSSProperties} variant="outlined">
                                    <CardContent>
                                        <img src={Infoboard} alt="logo" width={100} height={105} />
                                        <Typography variant="body2" style={{ color: 'black' }} component="p">
                                            Infoboard
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid></div>}
                        {this.state.statisticsTab == "Leaderboard" && <>
                            <div style={{ height: '100vh' }}>
                                <div style={{ display: 'flex', justifyContent: "center" }}>
                                    <img style={{ width: '100%', height: '250px' }} src={leader_icon} />
                                    <div style={webStyles.oneTwoThree} className="static_container">
                                        <div className="rectangleBox box_image_one" style={{ height: '163px' }}>
                                            <div className="rectangle_box_details" style={{ position: 'absolute', top: '-45px' }}>
                                                <img style={{ width: '65px' }} src={user_profile} />
                                                <div style={{ color: 'rgba(28, 160, 255, 1)', fontSize: '14px', fontWeight: 700 }}>2nd</div>
                                                <div style={{ color: 'rgba(0, 0, 0, 1)', fontSize: '16px', fontWeight: 700 }}>Daniel</div>
                                                <div style={{ color: 'rgba(120, 120, 120, 1)', fontSize: '14px' }}>Hiring Manager</div>
                                            </div>
                                        </div>
                                        <div className="rectangleBox box_image_two" style={{ height: '211px' }}>
                                            <div className="rectangle_box_details" style={{ position: 'absolute', top: '-110px' }}>
                                                <img style={{ width: '80px' }} src={pngegg} />
                                                <img style={{ width: '80px' }} src={user_profile} />
                                                <div style={{ color: 'rgba(28, 160, 255, 1)', fontSize: '14px', fontWeight: 700 }}>1nd</div>
                                                <div style={{ color: 'rgba(0, 0, 0, 1)', fontSize: '16px', fontWeight: 700 }}>Emma</div>
                                                <div style={{ color: 'rgba(120, 120, 120, 1)', fontSize: '14px' }}>Executive</div>
                                            </div >
                                        </div>
                                        <div className="rectangleBox box_image_three" style={{ height: '151px' }}>
                                            <div className="rectangle_box_details" style={{ position: 'absolute', top: '-43px' }}>
                                                <img style={{ width: '60px' }} src={user_profile} />
                                                <div style={{ color: 'rgba(28, 160, 255, 1)', fontSize: '14px', fontWeight: 700 }}>3nd</div>
                                                <div style={{ color: 'rgba(0, 0, 0, 1)', fontSize: '16px', fontWeight: 700 }}>Samantha</div>
                                                <div style={{ color: 'rgba(120, 120, 120, 1)', fontSize: '14px' }}>Executive</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '100px' }}>
                                    <div className="static-profile-tab">
                                        <h5 className={`${this.state.tabNumber == 1 ? "static-tab-border" : "static-tab"}`} onClick={() => { this.setState({ tabNumber: 1 }) }}>Monthly</h5>
                                        <h5 className={`${this.state.tabNumber == 2 ? "static-tab-border" : "static-tab"}`} onClick={() => { this.setState({ tabNumber: 2 }) }}>Yearly</h5>
                                        <h5 className={`${this.state.tabNumber == 3 ? "static-tab-border" : "static-tab"}`} onClick={() => { this.setState({ tabNumber: 3 }) }}>All-time</h5>
                                    </div>
                                    <div style={{ width: "100%", display: 'flex' }}>
                                        <div style={{ width: this.state.tabNumber == 3 ? '80%' : "100%",margin:"0px 30px" }}>
                                            {[1,2,3,4,5].map((item)=>{
                                                return(
                                            <Box display={'flex'} justifyContent={'space-between'}>
                                                <Box style={webStyles.meetingDetail} display={'flex'}>
                                                    <Box style={{ width: '33.33%', display: 'flex', alignItems: 'center' }}><img style={{ width: '48px', marginRight: '33px' }} src={oval} />Ralph Gomez</Box>
                                                    <Box style={{ width: '33.33%', display: 'flex', justifyContent: 'end' }}><div style={{ width: "300px", display: 'flex', justifyContent: 'flex-start' }}>UI Designer</div></Box>
                                                    <Box style={{ width: '33.33%', textAlign: "end" }}><img style={{ position: 'relative', left: '19px', top: '5px' }} src={Polygon} /><span style={{ position: 'relative', bottom: '6px', right: '9px', fontSize: '12px' }}>4th</span></Box>
                                                </Box>
                                            </Box>
                                                )
                                            })}
                                        </div>
                                        {this.state.tabNumber == 3 && <div style={{ paddingTop: '25px', paddingLeft: '37px', width: '20%', fontSize: '16px', color: "rgba(0, 0, 0, 1)" }}>
                                            <div style={{ fontWeight: 700 }}>Department</div>
                                            {["Design","Delivery","Procurement","Sales","Executive"].map((item)=>{
                                                return(
                                            <div style={{ display: 'flex', marginTop: '25px', columnGap: '10px' }}>
                                                <div><input style={{ width: "19px", height: '19px' }} type="checkbox" /></div>
                                                <div>{item}</div>
                                            </div>
                                                )
                                            })}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </>}
                        {this.state.statisticsTab == "Scoreboard" && <>
                            <div style={{ display: 'flex' }}>
                                <Box style={{width: this.state.scoreTabNumber == 0 ? "100%" : "50%" }}>
                                    <Box data-test-id="Monthly" style={webStyles.Scoreboard} display={'flex'} onClick={() => { this.setState({ scoreTabNumber: 1 }) }}>
                                        <Box style={{ fontWeight: this.state.scoreTabNumber == 1 ? 700 : 400 }}>Monthly</Box>
                                        <ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                                    </Box>
                                    <Box data-test-id="Yearly" style={webStyles.Scoreboard} display={'flex'} onClick={() => { this.setState({ scoreTabNumber: 2 }) }}>
                                        <Box style={{ fontWeight: this.state.scoreTabNumber == 2 ? 700 : 400 }}>Yearly</Box>
                                        <ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                                    </Box>
                                    <Box data-test-id="All-time" style={webStyles.Scoreboard} display={'flex'} onClick={() => { this.setState({ scoreTabNumber: 3 }) }}>
                                        <Box style={{ fontWeight: this.state.scoreTabNumber == 3 ? 700 : 400 }}>All time</Box>
                                        <ArrowForwardIosIcon style={{ fontSize: '18px', marginRight: '10px', marginLeft: '10px' }} />
                                    </Box>
                                </Box>
                                {this.state.scoreTabNumber > 0 && <Box style={{ margin: "0px 40px 0px 0px", width: "50%" }}>
                                    <Box style={webStyles.ScoreboardDetailTital} marginLeft={"0px"} display={'flex'}>
                                        <Box>KPI</Box>
                                        <Box>Rank</Box>
                                        <Box>Points</Box>
                                        <Box>Badges</Box>
                                    </Box>
                                    {[1, 2, 3, 4, 5, 6].map((item) => {
                                        return (
                                            <Box style={webStyles.ScoreboardDetail} marginLeft={"0px"} display={'flex'}>
                                                <Box style={{ width: '25%', display: "flex", justifyContent: 'center' }}>Sales</Box>
                                                <Box style={{ width: '25%', display: "flex", justifyContent: 'center' }}>1nd</Box>
                                                <Box style={{ width: '25%', display: "flex", justifyContent: 'center' }} color={"#0742BF"}>38/45</Box>
                                                <Box style={{ width: '25%', display: "flex", justifyContent: 'center' }} display={"flex"}>2
                                                    <StyledTooltip title={
                                                        <div style={{ padding: '13px', width: '100%' }}>
                                                            <div><img src={Medallion} /> Ranker</div>
                                                            <div><img src={Medallion} /> 20Pts Achieved</div>
                                                        </div>
                                                    } placement="right-start"
                                                    >
                                                        <ExpandLess />
                                                    </StyledTooltip>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>}
                            </div>
                        </>}
                        {this.state.statisticsTab == "Infoboard" && <>
                            <div style={webStyles.infoGrid}>
                                {[1,2,3,4].map((item)=>{
                                    return(
                                <div style={{ padding: "37px 27px", width: "100%" }}>
                                    <div style={{fontSize:'18px',fontWeight:600,marginBottom:'23px'}}>Monthly Parameters for
                                        the month!</div>
                                    <div style={{fontSize:'16px',fontWeight:400,height:'152px'}}>There are number of instructions to be followed at the time of refilling an inkjet cartridge. So whenever your printer ink runs dry you need to follow the below steps for inkjet cartridge refill.</div>
                                    <div className="control kpi-report-btn" id="signupfieldcontrol2" style={{textAlign:'end'}}>
                                        <button
                                            type="button"
                                            data-test-id="infoboard-submit"
                                            style={webStyles.cotinueBtn}
                                            className="button submit"
                                        >
                                            View More
                                        </button>
                                    </div>
                                </div>
                                    )
                                })}
                            </div>
                        </>}
                    </Grid>
                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}
const StyledTooltip = styled(Tooltip)({
    display: 'block'
})
// Customizable Area Start
const webStyles = {
    calendarWrapper: {
        display: "flex",
        justifyContent: "space-between",
        width: "298px",
        height: "62px",
        background: "#fff",
        alignItems: "center",
        padding: "15px 20px",
        borderRadius: "8px"
    },
    infoGrid:{
        display: "grid",
        gridTemplateColumns: "auto auto auto",
        color:'black',
        margin:'35px'
    },
    cotinueBtn: {
        margin: "20px 0 0",
        fontWeight: 600,
        height: "55px",
        width:'50%'
    },
    meetingDetail: {
        boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.1)",
        padding: "5px 44px",
        width: "100%",
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        margin: "0px 0px 24px",
        borderRadius: "20px",
        justifyContent: 'space-between',
        alignItems: "center",
    },
    Scoreboard: {
        padding: "19px 23px",
        boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        margin: "0px 30px 24px",
        borderRadius: "20px",
        fontWeight: 400,
        justifyContent: 'space-between',
        alignItems: "center",
        color: "#000000",
    },
    ScoreboardDetail: {
        padding: "19px 23px",
        background: "linear-gradient(180deg, #FFF26D 0%, #FFF9E2 130.37%)",
        width: "100%",
        fontSize: "16px",
        margin: "0px 30px 24px",
        fontWeight: 400,
        justifyContent: 'space-between',
        alignItems: "center",
        color: "#000000",
    },
    ScoreboardDetailTital: {
        width: "100%",
        padding: "19px 23px",
        background: "rgba(243, 244, 245, 1)",
        justifyContent: 'space-between',
        fontSize: "16px",
        margin: "0px 30px 24px",
        color: "#000000",
        alignItems: "center",
        fontWeight: 400,
    },
    oneTwoThree: {
        position: "absolute",
        top: "31%",
        display: 'flex',
        alignItems: "baseline"
    },
    maincontainer: {
        background: "#F3F4F5",
    },
    headerSection: {
        backgroundColor: '#F3F4F5',
        padding: '16px',
    },
    leftContainer: {
        padding: "19px 0px 21px 38px",
        alignItems: 'center',
        display: 'flex',
    },
    labelStyle1: {
        fontSize: "18px",
        lineHeight: '24px',
        color: '#000000',
        cursor: 'pointer'
    },
    labelTwoStyle1: {
        fontSize: "18px",
        lineHeight: '24px',
        color: '#000000',
        fontWeight: 600,
        cursor: 'pointer'
    },
    root: {
        minWidth: 275,
    },
} as any;
// Customizable Area End
