import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class ReportviewView extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.Reportviewmaincontainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.ReportviewleftSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.ReportviewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{ ...webStyle.ReportviewHeadStyleTop1, fontWeight: 400 }}>
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.ReportviewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ ...webStyle.ReportviewHeadStyleTop1, color: "#B2B2B2", fontSize: "16px" }} />
                  <span style={{ ...webStyle.ReportviewlabelStyleoptionhead as React.CSSProperties, fontWeight: 700, fontSize: "18px" }}>
                    View KPI Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" style={webStyle.ReportviewnullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.ReportviewinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.ReportviewinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.ReportviewheaderMain10}>
                    <span style={webStyle.ReportviewHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.Editbutton as React.CSSProperties} data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.ReportviewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ReportviewheaderMain10}>
                  <span style={{ ...webStyle.ReportviewlabelStyleoptionhead as React.CSSProperties, fontSize: "22px", fontWeight: 500 }}>Type</span>
                </FormLabel>
                <span style={{ ...webStyle.ReportviewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>Report</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.ReportviewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ ...webStyle.ReportviewheaderMain10, marginTop: "25px" }}>
                  <span style={{ ...webStyle.ReportviewlabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12} spacing={6}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.ReportviewtopdescThirty}>
                          <span style={webStyle.ReportviewTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.ReportviewmarginLeft0}>
                            <span style={webStyle.ReportviewblurvalueStyle as React.CSSProperties}>Report</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.ReportviewtopdescThirty}
                          >
                            <span style={webStyle.ReportviewTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.ReportviewmarginLeft0}
                            >
                              <span style={{ ...webStyle.ReportviewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>1 Missed Submission: 8 <br />2 Missed Submission: 10<br />3 Missed Submission: 0</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{ ...webStyle.ReportviewtopdescThirty, width: "max-content" }}
                          >
                            <span style={webStyle.ReportviewTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.ReportviewmarginLeft0}
                            >
                              <span style={{ ...webStyle.ReportviewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>10 points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
              </FormControl>
            </Grid >



          </Grid >
          <Divider 
          light style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Grid
            container
            alignItems="center"
            style={webStyle.ReportviewinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.ReportviewinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.ReportviewheaderMain10}>
                    <span style={webStyle.ReportviewHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.Editbutton as React.CSSProperties} data-test-id="nextButton">
                    Edit DKR Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.ReportviewinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Reportviewheadermandatory}>
                    <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable Occurrence</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.ReportviewtablemainContainer}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow style={webStyle.ReportviewmaintableHead}>
                        <TableCell style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", fontWeight: 600, paddingLeft: "70px", width: "33%" }}>Occurrence Title</TableCell>
                        <TableCell style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", fontWeight: 600, width: "33%" }}>
                          Deductible Value
                        </TableCell>
                        <TableCell style={{ width: "18%" }}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>

                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>1<sup>st</sup> Missed Occurrence</TableCell>
                        <TableCell>
                          <Box className="form-block" id="signupBlock3">
                            <form>
                              <Box
                                className="form-field"
                                id="signupform"
                              >
                                <Box
                                  className="control"
                                  id="signupfieldcontrol"
                                  style={webStyle.ReportviewmarginLeft0}
                                >
                                  <span style={webStyle.mainvalueStyle as React.CSSProperties}>2 Points</span>

                                </Box>
                              </Box>
                            </form>
                          </Box>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>2<sup>nd</sup> Missed Occurrence</TableCell>
                        <TableCell>
                          <Box className="form-block" id="signupBlock3">
                            <form>
                              <Box
                                className="form-field"
                                id="signupform"
                              >
                                <Box
                                  className="control"
                                  id="signupfieldcontrol"
                                  style={webStyle.ReportviewmarginLeft0}
                                >
                                  <span style={webStyle.mainvalueStyle as React.CSSProperties}>3 Points</span>

                                </Box>
                              </Box>
                            </form>
                          </Box>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>3<sup>rd</sup> Missed Occurrence</TableCell>
                        <TableCell>
                          <Box className="form-block" id="signupBlock3">
                            <form>
                              <Box
                                className="form-field"
                                id="signupform"
                              >
                                <Box
                                  className="control"
                                  id="signupfieldcontrol"
                                  style={webStyle.ReportviewmarginLeft0}
                                >
                                  <span style={webStyle.mainvalueStyle as React.CSSProperties}>5 Points</span>

                                </Box>
                              </Box>
                            </form>
                          </Box>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                </TableContainer>

              </>
            </Grid>
          </Grid >
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {

  ReportviewbuttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },
  ReportviewCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: "none",
    backgroundColor: "white",
    fontSize: "16px",
    width: "170px",
    color: "black",
  },

  ReportviewlabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  ReportviewTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  ReportviewblurvalueStyle: {
    color: "black",
    marginTop: "20px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  ReportviewtablemainContainer: {
    marginTop: "15px",
    borderRadius: "10px",
    border: "1px solid #979797",
    marginBottom: "10%"
  },
  ReportviewinformationSubContainerTable: {
    marginTop: "24px",
    width: "98%",
  },
  ReportviewrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  ReportviewheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
  ReportviewSubmitBtn: {
    fontFamily: 'Open Sans',
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    backgroundColor: "FDD100",
    fontWeight: 600,
    textTransform: "none",

  },


  informatSubContainerTable: { marginTop: "24px", width: "90%" },
  ReportviewinformSecondContainerHead: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
    alignItems: "initial",
    width: "98%"
  },
  ReportviewtopdescThirty: {
    marginTop: "20px",
  },
  container: {
    height: "100%",
    width: "100%",
  },


  TableC: {
    fontSize: "14px",
    color: "black",
    fontWeight: 600,
    fontFamily: "Open Sans",
  },

  Reportviewmaincontainer: {
    background: "#F3F4F5",
    height: "64px",
    marginRight: "-20px"
  },
  ReportviewinformContainer: {
    padding: "0 32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  ReportviewinformationSubject: {
    marginTop: "24px",
    width: "800px",
    marginBottom: "30px"
  },
  ReportviewleftSide: {
    padding: "19px 0px 21px 38px"
  },
  ReportviewnullContainer: {
    padding: "0px 44px 0px 0px"
  },
  ReportviewmaintableHead: {
    backgroundColor: "#F5F5F5",
  },
  labelStyle10: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    fontWeight: 500,
    color: "black",
  },
  Reportviewheadermandatory: {
    display: "flex",
    MarginTop: "30px",
    alignItems: "baseline",
    gap: "4px",
  },
  mainvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  inputmainTable: {
    background: 'transparent',
    border: '0.5px solid #979797',
    width: "70%",
  },
  Editbutton: {
    backgroundColor: "FDD100",
    color: "black",
    fontFamily: 'Open Sans',
    padding: "15px 20px 15px 20px",
    width: "200px",
    fontSize: "16px",
    borderRadius: "10px",
    fontWeight: 600,
    textTransform: "none"
  },


  ReportviewHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  ReportviewHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },

  ReportviewmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },

};
// Customizable Area End
