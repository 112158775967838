import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Paper,
  Modal,

} from "@material-ui/core";
// Customizable Area End

import SettingsController, {
  Props
} from "./SettingsController";
import { visibility_eye, visibility_eye_off } from "../../forgot-password/src/assets";
import { arrow_left } from "../../Gamification/src/assets";
import { Arrow_left_light, Success } from "./assets";

export default class ChangePassword extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { showPassword, open, passwords, errors, modalMessage, oldPasswordErrorMessage } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderSection>
          <img onClick={() => this.backNavigate("EmployeeDashboard")} data-test-id="NavigateBack" style={{ height: '14px', width: '9px', cursor: 'pointer' }} src={arrow_left} alt="<" />
          <SettingsTypography variant="h5" >
            Settings</SettingsTypography>
          <img onClick={() => this.backNavigate("Settings")} data-test-id="NavigateBackId" style={{ cursor: 'pointer' }} src={Arrow_left_light} alt="<" />
          <ChangePasswordTypography>Change password</ChangePasswordTypography>
        </HeaderSection>
        <ChangePasswordBox>
          <TitleTypography variant="h6" style={{ marginBottom: "12px" }}>Old password</TitleTypography>
          <StyledTextField
            fullWidth
            variant="outlined"
            placeholder="Enter old password"
            value={passwords.oldPassword}
            data-test-id="textFieldOne"
            onChange={(e) => this.handlePasswordChange("oldPassword", e.target.value)}
            type={showPassword.oldPassword ? "text" : "password"}
            error={errors.oldPassword || errors.originOldPassworderror}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" data-test-id="iconButtonClickOne" onClick={() => this.toggleShowPassword("oldPassword")}  >
                    <img src={showPassword.oldPassword ? visibility_eye : visibility_eye_off} alt="" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            style={{ marginBottom: "25px", background: "#F7F7F7" }}
          />
          {errors.oldPassword && (
            <OldPasswordTypographyOne >
              Please enter old password
            </OldPasswordTypographyOne>
          )}
          {errors.originOldPassworderror && (
            <OldPasswordTypographyTwo>
              {oldPasswordErrorMessage}
            </OldPasswordTypographyTwo>
          )}
          <TitleTypography variant="h6" style={{ marginBottom: "12px" }}>New password</TitleTypography>
          <StyledTextField
            fullWidth
            variant="outlined"
            placeholder="Enter new password"
            value={passwords.newPassword}
            data-test-id="textFieldTwo"
            onChange={(e) => this.handlePasswordChange("newPassword",e.target.value)}
            type={showPassword.newPassword ? "text" : "password"}
            error={errors.newPassword || this.state.showStrictPwd}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => this.toggleShowPassword("newPassword")}>
                    <img src={showPassword.newPassword ? visibility_eye : visibility_eye_off} alt="toggle visibility" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: "25px", background: "#F7F7F7" }}
          />
          {errors.newPassword && (
            <NewPasswordTypography >
              Please enter new password
            </NewPasswordTypography>)}
          {this.state.showStrictPwd && (
            <NewPasswordTypography >
              {this.state.showStrictPwd}
            </NewPasswordTypography>
          )}
          <TitleTypography variant="h6" style={{ marginBottom: "12px" }}>Confirm password</TitleTypography>
          <Box style={{ position: 'relative' }}>
            <StyledTextField
              fullWidth
              variant="outlined"
              placeholder="Confirm password"
              value={passwords.confirmPassword}
              data-test-id="textFieldThree"
              onChange={(e) => this.handlePasswordChange("confirmPassword", e.target.value)}
              type={showPassword.confirmPassword ? "text" : "password"}
              error={errors.confirmPassword || errors.passwordMismatch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <IconButton edge="end" onClick={() => this.toggleShowPassword("confirmPassword")}>
                      <img src={showPassword.confirmPassword ? visibility_eye : visibility_eye_off} alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: "60px", background: "#F7F7F7" }}
            />
            {(errors.passwordMismatch) && (
              <ConfirmPasswordTypographyOne >
                New and Confirm password does not match
              </ConfirmPasswordTypographyOne>
            )}
            {(errors.confirmPassword) && (
              <ConfirmPasswordTypographyTwo >

                Please enter confirm password
              </ConfirmPasswordTypographyTwo>
            )}
          </Box>
          <SaveButton data-test-id="saveButtonId" fullWidth variant="contained" onClick={this.handleOpenModal}>
            <SaveTypography> Save</SaveTypography>
          </SaveButton>
        </ChangePasswordBox>
        <Modal open={open} >
          <ModalBox>
            <SuccessImageBox >
              <img src={Success} alt="success" />
            </SuccessImageBox>
            <Box style={{ margin: '24px 0 20px 0' }}>
            </Box>
            <CongratsTypography>Congrats!</CongratsTypography>
            <ModalMessageTypography >{modalMessage}</ModalMessageTypography>
            <SubmitBackBox onClick={() => this.handleBack("EmployeeDashboard")} data-test-id="handleCloseSubmitId"><SubmitTypography >Back to Home</SubmitTypography></SubmitBackBox>
          </ModalBox>
        </Modal>
      </>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
const ChangePasswordBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "450px",
  padding: "0px 32px",
});

const SaveButton = styled(Button)({
  background: '#FFCC00',
  padding: '14px 0 14px 0',
  borderRadius: '8px',
  textTransform: 'unset',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#FFCC00',
    color: '#000000',
    cursor: 'pointer',
  },
  boxShadow: 'unset'
});
const SaveTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: '#000000',
})
const HeaderSection = styled(Paper)({
  padding: '20px 32px',
  fontFamily: 'Open Sans',
  marginBottom: '32px',
  background: '#F3F4F5',
  display: 'flex',
  alignItems: 'center',
  gap: '21px',
});
const SettingsTypography = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '18px',
  fontWeight: 400,
  color: '#000000',
  lineHeight: '24px'
});
const ChangePasswordTypography = styled(Typography)({
  color: '#000000',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '20px',
})
const StyledTextField = styled(TextField)({
  borderRadius: "8px",
  width: '100%',
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: '0.25px solid #00000047',
      borderRadius: "8px",
    },
    "&:hover fieldset": {
      borderColor: "#FFCC00",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFCC00",
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: '22px',
      color: '#959595'
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: '-7px',
    }
  },
});
const TitleTypography = styled(Typography)({
  color: '#000000',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '24px'
})
const SubmitBackBox = styled(Box)({
  maxWidth: '247px',
  width: "100%",
  marginBottom: "16px",
  background: '#FFCC00',
  padding: '14px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  margin: "auto",
  borderRadius: '8px',
})
const ModalBox = styled(Box)({
  position: 'absolute',
  width: '550px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '24px 20px',
  borderRadius: '8px',
})
const SubmitTypography = styled(Typography)({
  color: '#000000', fontWeight: 600, fontSize: '16px', cursor: 'pointer'
})
const CongratsTypography = styled(Typography)({
  color: '#000000',
  fontSize: '30px',
  fontWeight: 700,
  lineHeight: '30px',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: "20px",
})
const ConfirmPasswordTypographyOne = styled(Typography)({
  color: 'red',
  fontSize: '14px',
  position: 'absolute',
  top: '61px'
})
const SuccessImageBox = styled(Box)({
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '40px'
})
const ModalMessageTypography = styled(Typography)({
  color: '#000000',
  fontSize: '20px',
  fontWeight: 400,
  marginBottom: '40px',
  opacity: '58%',
  display: 'flex',
  justifyContent: 'center'})
  
const ConfirmPasswordTypographyTwo = styled(Typography)({
  color: 'red',
  fontSize: '14px',
  position: 'absolute',
  top: '61px'
})
const OldPasswordTypographyOne = styled(Typography)({
  color: 'red',
  fontSize: '14px',
  marginTop: '-17px'
})
const OldPasswordTypographyTwo = styled(Typography)({
  color: 'red',
  fontSize: '14px',
  marginTop: '-17px'
})
const NewPasswordTypography = styled(Typography)({
 color: 'red',
  fontSize: '14px', 
  marginTop: '-17px'
})
// Customizable Area End