import React from "react";
// Customizable Area Start
import "./dashboard.css";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import {Button,Modal} from '@material-ui/core';
import PersonIcon from "@material-ui/icons//Person";
import { LineChart, Line, XAxis, YAxis, Tooltip, LabelList, ReferenceLine } from "recharts";
import { AntSwitch } from "./Users.web";
import { webStyle } from "./EditTemplate.web";
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import AddIcon from '@material-ui/icons/Add';


export default class Dashboard extends DashboardController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const name = window.localStorage.getItem("adminName")
    const medianValue1 = this.calculateMedian(this.state.dataSource);
    const medianValue2 = this.calculateMedian(this.state.dataSource2);
    return (
      // Customizable Area Start
      <div className="content-wrapper">
        <div className="dashboard-info">
          <div className="welcome-user">
            <h2>Hi, {name}</h2>
            <div className="row">
              <div className="col">
                <div className="inner-col" style={webStyle2.Div1}>
                  <h2 style={webStyle2.Heading2}>{this.state.totalTemplate}</h2>
                  <p style={webStyle2.Paragraph}>Templates Created</p>
                </div>
              </div>
              <div className="col">
                <div className="inner-col" style={webStyle2.Div2}>
                  <h2 style={webStyle2.Heading2}>{this.state.activeUsersCount}</h2>
                  <p style={webStyle2.Paragraph}>Active Users</p>
                </div>
              </div>
              <div className="col">
                <div className="inner-col" style={webStyle2.Div3}>
                  <h2 style={webStyle2.Heading2}>115</h2>
                  <p style={webStyle2.Paragraph}>Templates Assigned</p>
                </div>
              </div> 
            </div>
          </div>
          <div className="upload-user-data">
            <button style={webStyle2.Button} className="button" onClick={this.handleUploadClick}>
          <AddIcon style={webStyle2.Icon}/> Upload User Data
              
            </button>
            {this.state.flagButton &&(
               <Box sx={{ minWidth: 40 }}>
               <Card
                 variant="outlined"
                 style={webStyle2.Card1 as React.CSSProperties}
               >
                 <Button
                 data-test-id="singleuser"
                   onClick={()=> this.props.navigation.navigate("SingleUserUpload")}
                   style={webStyle2.Button4 as React.CSSProperties}
                 >
                   <label  style={webStyle2.label1 as React.CSSProperties}>
                   <PersonIcon style={webStyle2.Person as React.CSSProperties} />
                   Single User Upload
                   </label>
                 </Button>
                 <Button
                 
                   style={webStyle2.Button3 as React.CSSProperties}
                 >
                  <label htmlFor="bulk-upload" style={webStyle2.label1 as React.CSSProperties}>
                    <PersonIcon style={webStyle2.Person as React.CSSProperties}  />
                    Bulk Upload
                  </label>
                 <input accept=".csv" type="file" style={webStyle2.input as React.CSSProperties} onChange={(e)=>{this.handleFileChange(e)}} id="bulk-upload" />
                

                 </Button>
               </Card>
             </Box>
            )
            }
          </div>
        </div>
        <div className="dashboard-chart-block">
          <div className="graph-row">
            <div className="col">
              <div className="dashboard-chart">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="bar"
                  height={350}
                  width={500}

                />
              </div>
            </div>
            <div className="col">
              <div className="dashboard-chart">
                <LineChart
                  layout="horizontal"
                  width={500}
                  height={360}
                  data={this.state.dataSource}
                  margin={{
                    top: 60,
                    right: 30,
                    left: 20,
                    bottom: 10
                  }}
                >
                  <XAxis dataKey="name" type="category" />
                  <YAxis type="number" />
                  <Tooltip />
                  <text
                    x={30}
                    y={30}
                    textAnchor="right"
                    fontWeight="bold"
                    style={webStyle2.text as React.CSSProperties}
                    fill="#a0a0a0"
                  >
                    Performance (Last Quarter)
                  </text>
                  <Line dataKey="pv" stroke="#FCD61D" dot={{ fill: "#486484" }}>
                    <LabelList dataKey="pv" position="top" />
                  </Line>
                 
                    <ReferenceLine
                      y={medianValue1}
                      stroke="#E4E4E4"
                      strokeDasharray="7 6"
                      width={249.9}
                      style={webStyle2.Ref as React.CSSProperties}
                    />
                 
                </LineChart>
              </div>
            </div>
            <div className="col">
              <div className="dashboard-chart">
                <LineChart
                  layout="horizontal"
                  width={500}
                  height={360}
                  data={this.state.dataSource2}
                  margin={{
                    top: 60,
                    right: 30,
                    left: 20,
                    bottom: 10
                  }}
                >
                  <XAxis dataKey="name" type="category" />
                  <YAxis type="number" />
                  <Tooltip />
                  <text
                    x={30}
                    y={30}
                    textAnchor="right"
                    fontWeight="bold"
                    fill="#a0a0a0"
                    style={webStyle2.text as React.CSSProperties}
                  >
                    User Growth (Last Quarter)
                  </text>

                  <Line dataKey="pv" stroke="#486484" dot={{ fill: "#9BBE6A" }}>
                    <LabelList dataKey="pv" position="top" />
                  </Line>

                  
                    <ReferenceLine
                      y={medianValue2}
                      stroke="#E4E4E4"
                      strokeDasharray="7 6"
                      width={249.9}
                      style={webStyle2.Ref as React.CSSProperties}
                    />
                  
                </LineChart>

              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-table">
          <div className="table-header">
            <h4>Employee Details</h4>
            <Link to="/Users">View All</Link>
          </div>
          <div className="responsive-table">
            <table className="table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>Employee ID</th>
                  <th>Employee Name</th>
                  <th>Department</th>
                  <th>KPI Progress</th>
                  <th>Enable/Disable</th>
                
                 
                </tr>
              </thead>
              <tbody className="tbody">
                {this.state.viewAllUserData.map((item: any, key: number) => {
                  return (
                    <>
                    <tr data-test-id="rows" key={key} className="tabel-rows">
                      <td className={this.getClassName(item.attributes.activated)} data-id="table-id">
                        {item.attributes.employee_id
                          ? item.attributes.employee_id
                          : "-"}
                      </td>
                      <td className={this.getClassName(item.attributes.activated)} data-test-id="attr-name-id">
                        <span className="attr-name"> {item.attributes.name
                          ? item.attributes.name
                          : "-"}</span>
                      </td>
                      <td className={this.getClassName(item.attributes.activated)}>
                        {item.attributes.department?.name
                          ? item.attributes.department?.name
                          : "-"}
                      </td>
                      <td className={this.getClassName(item.attributes.activated)}>
                        </td>
                          <td className={this.getClassName(item.attributes.activated)} id="fake">
                            <AntSwitch
                           data-test-id="emp_switch__"
                              checked={
                                item.attributes.activated}
                              onClick={() =>
                               { this.handleSwitchChange(item.id,item.attributes.name);this.handleOpenKpiModal()}
                              }
                              name="checked"
                              id={`switch-${item.id}`}
                            />
                          </td>
                     
                    </tr>
                   
                </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
                                        style={webStyle2.modal}
                                        open={this.state.disableKpiModel}
                                        onClose={this.handleOpenKpiModal}
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                    >
                                        <>
                                            <div style={webStyle2.kpiModel2 as React.CSSProperties}>

                                                <Typography style={webStyle2.Typography}>Are you sure you want to disable/enable the account of {this.state.accountsName} ? </Typography>
                                                <div style={webStyle.KpiModelfooterStyle}>
                                                    <Button data-test-id="saveokay" onClick={this.confirmDisableAccount} style={webStyle2.buttonsubmit2  as React.CSSProperties}
                                                    >Yes</Button>
                                                    <div style={webStyle.buttoncancel}>
                                                        <Button

                                                            onClick={this.handleOpenKpiModal}
                                                            variant="outlined"
                                                            style={webStyle2.Button2 as React.CSSProperties}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    </Modal>
      </div>
      // Customizable Area End  
    );
  }
}
// Customizable Area Start
const webStyle2 = {
  Heading2: {
    fontSize:"38px",color:"white"
  } ,
  Paragraph: {
    fontSize:"16px",color:"white"
  },
  Button: {
    cursor:"pointer", fontSize:"16px",borderRadius: "15px" ,display: "flex", justifyContent: "center",alignItems: "center" , fontWeight: 700
  },
  Div1 : {
     background: " #6191C7" 
  },
  Div2 : {
     background: "#486484" 
  },
  Div3 : {
     background: "#4E53A6" 
  },
  Icon: {
    fontSize: "30px" , fontWeight:700
  },
  modal : {
    
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
  
  },
  Typography : {
    
      color: "#000",
      fontSize: "18px",
      fontStyle: "normal", fontWeight: 700, lineHeight: "normal", padding: "5px 0px 30px 25px"
  
  },
  Button2: {
    
    backgroundColor: 'white',
    color: 'black',
    width: '80px',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    fontFamily: "Open Sans"
  
  },
  Button3:{
     textAlign: "left", textTransform: "none" 
  },
  Card1 :{
    
      width: 210,
      marginTop:"10px",
      height: 80.23,
      border: "none", // Remove the black border
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add a shadow
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "8px"
    
  },
  Button4:{
    textAlign: "left", textTransform: "none" 
  },
  Person:{
    fontSize: 14, marginRight: 1, width:16,height:16 
  },
  text:{
     fontSize: '20px', fontWeight: 'bold' }
  ,
  label1:{
     fontSize: 12 
  },
  input : {
    display:'none'
  },
  Ref :{
      strokeWidth: "3px"
  },
  kpiModel2 : {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '420px',
    backgroundColor: 'white',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    padding: '20px 5px 20px 5px'
  },
  buttonsubmit2 :{
    backgroundColor: '#FDD100',
    color: 'black',
    // marginRight: '10px',
    width: '80px',
    fontSize: '12px',
    fontWeight: 600,
    textTransform: 'none',
    fontFamily: "Open Sans"
  }

}

// Customizable Area End

