import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  styled,
  Checkbox,
  Grid,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
// Customizable Area End

import RolesPermissionsController, {
  Props,
  configJSON,
} from "./RolesPermissionsController";

export default class RolesPermissions extends RolesPermissionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {managersName,EmployeesName}=this.state
    return (
      // Customizable Area Start
      <>
        <MainHeaderSection>
          <KPIdetailsTypography variant="h5">Permissions</KPIdetailsTypography>
        </MainHeaderSection>
        <Container maxWidth="xl" style={{ padding: "0px 32px" }}>
          <Grid container spacing={4}>
            <Grid item xs={6} style={{
              height: '90vh',
              overflowY: 'scroll'
            }}>
              <CategoryTitle variant="h6">Team Leads/Managers</CategoryTitle>
              <PermissionList>
                {managersName.map((name:any) => (
                  <PermissionRow key={name}>
                    <Box>
                      <NameTypography>{name}</NameTypography>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <CheckBoxContainer>
                        <CustomCheckbox
                          disableRipple
                          disableFocusRipple
                          color="primary" />
                        <ViewTypography>View</ViewTypography>
                      </CheckBoxContainer>
                      <CheckBoxContainer>
                        <CustomCheckbox
                          disableRipple
                          disableFocusRipple
                          color="primary" />
                        <EditTypography>Edit</EditTypography>
                      </CheckBoxContainer>
                    </Box>
                  </PermissionRow>
                ))}
              </PermissionList>
            </Grid>
            <Grid item xs={6} style={{
              height: '90vh',
              overflowY: 'scroll'
            }}>
              <CategoryTitle variant="h6">Employees</CategoryTitle>
              <PermissionList>
                {EmployeesName.map((name:any) => (
                  <PermissionRow key={name}>
                    <Box>
                      <Typography>{name}</Typography>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                      <CheckBoxContainer>
                        <CustomCheckbox
                          disableRipple
                          disableFocusRipple
                          color="primary" />
                        <ViewTypography>View</ViewTypography>
                      </CheckBoxContainer>
                      <CheckBoxContainer>
                        <CustomCheckbox
                          disableRipple
                          disableFocusRipple
                          color="primary" />
                        <EditTypography>Edit</EditTypography>
                      </CheckBoxContainer>
                    </Box>
                  </PermissionRow>
                ))}
              </PermissionList>
            </Grid>
          </Grid>
        </Container>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomCheckbox = styled(Checkbox)({
  "&:hover": {
    backgroundColor: "transparent",
  },
  cursor: "pointer",
  marginRight: '-10px'
});
const MainHeaderSection = styled(Box)({
  padding: "20px 32px",
  fontFamily: "Open Sans",
  marginBottom: "34px",
  background: "#F3F4F5",
  display: "flex",
  alignItems: "center",
  gap: "21px",
});

const KPIdetailsTypography = styled(Typography)({
  fontFamily: "Open Sans",
  fontSize: "18px",
  fontWeight: 700,
  color: "#000000",
  lineHeight: "24px",
});

const CategoryTitle = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.2571428716182709px',
  color: '#000000',
  marginBottom: '19px',
});


const PermissionList = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});
const CheckBoxContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  gap: '8px',
});
const PermissionRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: '0px 4px 18px 0px #00000017',
  borderRadius: '12px',
  minHeight: '58px',
  padding: '0px 25px',
  marginBottom: '16px',
});
const ViewTypography = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21.79px',
  color: '#000000'
})
const EditTypography = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21.79px',
  color: '#000000'
})
const NameTypography = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#707070',
});

// Customizable Area End
