import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, Modal, Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { editable, disableEdit } from "./assets";
import { Link } from "react-router-dom";
import { webStyle } from "./EditTemplate.web";
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";


export default class Users extends DashboardController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
   const modal= ()=>{
    return   <Modal
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
    data-testId="confirmationModal"
    open={this.state.disableKpiModel}
    onClose={this.handleOpenKpiModal}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <>
      <div style={webStyle.kpiModel as React.CSSProperties} className="kpi-model">

        <Typography data-testid="accountnameofmodal" style={{
          fontSize: "18px",
          color: "#000",
          lineHeight: "normal",
          fontStyle: "normal", fontWeight: 700,  padding: "72px 0px 79px 66.93px"
        }}>Are you sure you want to disable/enable the account of {this.state.accountsName} ? </Typography>
        <div id="modal-footer" style={webStyle.KpiModelfooterStyle}>
          <Button className="modalConfirmationBtn" data-test-id="save" onClick={() => { this.confirmDisableAccount() }} style={webStyle.buttonsubmit}
          >Done</Button>
          <div style={webStyle.buttoncancel} data-test-id="cancleBtnDivForModal">
            <Button

              onClick={this.handleOpenKpiModal}
              className="modalCancelBtn"
              variant="outlined"
              style={{
                width: "100%",
                maxWidth: "101px",
                borderRadius: "8px",
                height: "40px",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>

      </div>
    </>
  </Modal>
   }
    return (
      // Customizable Area Start
      <>
        <div className="content-wrapper">
          <div className="user-table">
            <div className="responsive-table">
              <table className="table" cellSpacing="0" cellPadding="0">
                <thead>
                  <tr>
                    <th>Employee ID</th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Templates</th>
                    <th>KPI Progress</th>
                    <th>Enable</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.viewAllUserData.map((item: any, key: number) => {

                    return (
                      <tr data-test-id="rows" key={key}>
                        <td className={this.getClassName(item.attributes.activated)}>
                          {item.attributes.employee_id
                            ? item.attributes.employee_id
                            : "-"}
                        </td>
                        <td className={this.getClassName(item.attributes.activated)}>
                          <span className="attr-name"> {item.attributes.name
                            ? item.attributes.name
                            : "-"}</span>
                        </td>
                        <td className={this.getClassName(item.attributes.activated)}>
                          {item.attributes.department?.name
                            ? item.attributes.department?.name
                            : "-"}
                        </td>
                        <td className={this.getClassName(item.attributes.activated)}>
                          NA
                        </td>
                        <td className={this.getClassName(item.attributes.activated)}>
                          <Box position="relative" display="inline-flex">
                            <CircularProgress
                              data-test-id="progress"
                              variant="determinate"
                              value={item.attributes.kpis[0]?.progress}
                              style={this.getStatusStyle(item.attributes.kpis[0]?.progress !== undefined, !item.attributes.activated)}
                            />
                            <Box
                              top={0}
                              left={0}
                              bottom={0}
                              right={0}
                              position="absolute"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography
                                data-test-id="typography"
                                variant="caption"
                                component="div"
                                color="textSecondary"
                              >{item.attributes.kpis[0]?.progress ? item.attributes.kpis[0]?.progress + "%" : ""}</Typography>
                            </Box>
                          </Box>
                        </td>
                        <td className={this.getClassName(item.attributes.activated)}>
                          <AntSwitch
                            data-test-id="emp_switch"
                            checked={item.attributes.activated}
                            onClick={() => { this.handleSwitchChange(item.id, item.attributes.name); this.handleOpenKpiModal() }
                            }
                            name="checked"
                            id={`switch-${item.id}`}
                          />
                        </td>
                        <td className="action">
                          {item.attributes.activated ? (
                            <Link
                              onClick={() => this.handleEditClick(item)}
                              to={`/users/${item.id}`}>
                              <img src={editable} width={30} height={30} />
                            </Link>
                          ) : (
                            <img src={disableEdit} width={30} height={30} />
                          )}


                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        {modal()}
        </div>

      </>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
export const AntSwitch = withStyles(theme => ({
  root: {
    width: 50,
    height: 26,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#6192c8",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 13,
    backgroundColor: "#bcbeca",
    opacity: 1,
  },
  checked: {},
}))(Switch);
 // Customizable Area End
