import React from "react";
import { Drawer, List, ListItem, ListItemIcon } from "@material-ui/core";
import {
  kpimainlogo,
  homeicon,
  template,
  users,
  department,
  reports,
  permissions,
  homeActive,
  permissionsActive,
  reportsActive,
  usersActive,
  departmentActive,
  templateActive, KpiLogo, inactive_calendar, active_calendar, profile_sidebar, setting


} from "../../blocks/dashboard/src/assets"
import { Link } from "react-router-dom";
import { getStorageData } from "../../framework/src/Utilities";



export default class Sidebar extends React.Component {
  state = {
    flag: false
  }


  dataFromStorage = async () => {
    let result = await getStorageData("loginMessage")
    if (result === "org_admin") {
      this.setState({ flag: true })
    }
    else {
      this.setState({ flag: false })
    }
  }
  componentDidMount(): void {
    this.dataFromStorage()
  }

  render() {

    const list = [
      {
        name: "Home",
        url: "/Dashboard",
        icon: <img src={homeicon} width={30} height={30} />,
        activeicon: <img src={homeActive} width={30} height={30} />,
        activeroutes: ["/Dashboard", "/Home", "/createusers"]
      },
      {
        name: "Template",
        url: "/Template",
        icon: <img src={template} width={30} height={30} />,
        activeicon: <img src={templateActive} width={30} height={30} />,
        activeroutes: ["/Template","/TemplateAdded","/EditTemplate2","/EditKpi","/AddDkr","/AddDkr5","/ViewKpi5","/ViewKpi"]
      },
      {
        name: "Users",
        url: "/users",
        icon: <img src={users} width={30} height={30} />,
        activeicon: <img src={usersActive} width={30} height={30} />,
        activeroutes: ["/users", "/users/"]
      },
      {
        name: "Department",
        url: "/department",
        icon: <img src={department} width={30} height={30} />,
        activeicon: <img src={departmentActive} width={30} height={30} />,
        activeroutes: ["/department"]
      },
      {
        name: "Reports",
        url: "/reports",
        icon: <img src={reports} width={30} height={30} />,
        activeicon: <img src={reportsActive} width={30} height={30} />,
        activeroutes: ["/reports", "/Reports/BusinessTrends"],
      },
      {
        name: "Permissions",
        url: "/RolesPermissions",
        icon: <img src={permissions} width={30} height={30} />,
        activeicon: <img src={permissionsActive} width={30} height={30} />,
        activeroutes: ["/RolesPermissions"]
      },
      {
        name: "Calendar",
        url: "/calendar",
        icon: <img src={inactive_calendar} width={30} height={30} />,
        activeicon: <img src={active_calendar} width={30} height={30} />,
        activeroutes: ["/calendar"]
      },
    ];

    const employee_list = [
      {
        name: "Home",
        url: "/EmployeeDashboard",
        icon: <img src={homeicon} width={30} height={30} />,
        activeicon: <img src={homeActive} width={30} height={30} />,
        activeroutes: ["/EmployeeDashboard", "/Home", "/Notifications","/GamificationWeb","/ViewKpiDetails","/DailySubmissions",]
      },
      {
        name: "Profile",
        url: "/EmployeeProfiles",
        icon: <img src={profile_sidebar} width={30} height={30} />,
        activeicon: <img src={profile_sidebar} width={30} height={30} />,
        activeroutes: ["/EmployeeProfiles"]
      },

      {
        name: "Calendar",
        url: "/calendar",
        icon: <img src={inactive_calendar} width={30} height={30} />,
        activeicon: <img src={active_calendar} width={30} height={30} />,
        activeroutes: ["/Calendar"]
      },
      {
        name: "Settings",
        url: "/Settings",
        icon: <img src={setting} width={30} height={30} />,
        activeicon: <img src={setting} width={30} height={30} />,
        activeroutes: ["/Settings","/ChangePassword",]
      },
    ];

    return (
      <>
        <div className="page-sidebar">
          <div className="site-logo">


            <a href="#">
              <img className="desktop-logo" src={KpiLogo} alt='logo' />
              <img className="mobile-logo" src={kpimainlogo} alt='logo' />
            </a>
          </div>
          <div className="navigation">
            <nav className="main-nav">
              <ul>
                {this.state.flag ? (
                  list.map(item => (
                    <li>
                      <ListItem
                        button
                        component={Link}
                        to={`${item.url}`}

                        className={
                          Array.isArray(item.activeroutes) &&
                            item.activeroutes.includes(window.location.pathname)
                            ? "sidebar-list-item-active"
                            : "sidebar-list-item"
                        }
                      >
                        <ListItemIcon>

                          {item.activeroutes.includes(window.location.pathname)
                            ? item.activeicon
                            : item.icon}
                        </ListItemIcon>
                        <span className="menu-item-text">{item.name}</span>
                      </ListItem>
                    </li>
                  ))
                ) : (
                  employee_list.map(item => (
                    <li>
                      <ListItem
                        button
                        component={Link}
                        to={`${item.url}`}

                        className={
                          Array.isArray(item.activeroutes) &&
                            item.activeroutes.includes(window.location.pathname)
                            ? "sidebar-list-item-active"
                            : "sidebar-list-item"
                        }
                      >
                        <ListItemIcon>

                          {item.activeroutes.includes(window.location.pathname)
                            ? item.activeicon
                            : item.icon}
                        </ListItemIcon>
                        <span className="menu-item-text">{item.name}</span>
                      </ListItem>
                    </li>
                  ))
                )}

              </ul>



            </nav>
          </div>
        </div>

      </>
    );
  }
}
