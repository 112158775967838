import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class AttendenceView  extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.AttendenceViewmaincontainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.AttendenceViewleftSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.AttendenceViewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{ ...webStyle.AttendenceViewHeadStyleTop1, fontWeight: 400 }}>
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.AttendenceViewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ ...webStyle.AttendenceViewHeadStyleTop1, color: "#B2B2B2", fontSize: "16px" }} />
                  <span style={{ ...webStyle.AttendenceViewlabelStyleoptionhead as React.CSSProperties, fontWeight: 700, fontSize: "18px" }}>
                    View KPI Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" style={webStyle.AttendenceViewnullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.AttendenceViewinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.AttendenceViewinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.AttendenceViewheaderMain10}>
                    <span style={webStyle.AttendenceViewHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.AttendenceViewEditbutton as React.CSSProperties} data-test-id="Editbutton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.AttendenceViewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.AttendenceViewheaderMain10}>
                  <span style={{ ...webStyle.AttendenceViewlabelStyleoptionhead as React.CSSProperties, fontSize: "22px", fontWeight: 500 }}>Type</span>
                </FormLabel>
                <span style={{ ...webStyle.AttendenceViewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>Attendance</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item  
              style={webStyle.AttendenceViewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ ...webStyle.AttendenceViewheaderMain10,  }}>
                  <span style={{ ...webStyle.AttendenceViewlabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12} spacing={6}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.AttendenceViewtopdescThirty}>
                          <span style={webStyle.AttendenceViewTopstyleHeader as React.CSSProperties}>Title</span></div>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.AttendenceViewmarginLeft0}>
                            <span style={webStyle.AttendenceViewblurvalueStyle as React.CSSProperties}>Attendance</span>
                          </div>
                       
                      </form>
                    </div>
                  </Grid>
                    <Grid item xs={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.AttendenceViewtopdescThirty}
                          >
                            <span style={webStyle.AttendenceViewTopstyleHeader as React.CSSProperties}>Description</span></div>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.AttendenceViewmarginLeft0}
                            >
                              <span style={{ ...webStyle.AttendenceViewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>1 Absence: 8 points<br />2 Absence: 5 points<br />3 Absence: 0 points</span>
                            </div>
                          
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{ ...webStyle.AttendenceViewtopdescThirty, width: "max-content" }}
                          >
                            <span style={webStyle.AttendenceViewTopstyleHeader as React.CSSProperties}>Points (Weightage)</span></div>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.AttendenceViewmarginLeft0}
                            >
                              <span style={{ ...webStyle.AttendenceViewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>10 points</span>
                            </div>
                         
                        </form>
                      </div>
                    </Grid>
                
                </Grid>
              </FormControl>
            </Grid >



          </Grid >
          <Divider light style={{ marginTop: "40px", marginBottom: "20px" }} />
          <Grid
            container
            alignItems="center"
            style={webStyle.AttendenceViewinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.AttendenceViewinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.AttendenceViewheaderMain10}>
                    <span style={webStyle.AttendenceViewHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                <Button style={webStyle.AttendenceViewEditbutton as React.CSSProperties} data-test-id="nextButton">
                Edit DKR Details
            </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.AttendenceViewinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.AttendenceViewheadermandatory}>
                    <span style={{ ...webStyle.AttendenceViewlabelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable Occurrence</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.AttendenceViewtablemainContainer}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow style={webStyle.AttendenceViewmaintableHead}>
                        <TableCell style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", fontWeight: 600, paddingLeft: "70px", width: "33%" }}>Occurrence Title</TableCell>
                        <TableCell style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", fontWeight: 600, width: "33%" }}>
                          Deductible Value
                        </TableCell>
                        <TableCell style={{ width: "18%" }}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                   
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>1<sup>st</sup> Absence Occurrence</TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.AttendenceViewmarginLeft0}
                                  >
                                 <span style={webStyle.AttendenceviewmainvalueStyle as React.CSSProperties}>2 Points</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>2<sup>nd</sup> Absence Occurrence</TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.AttendenceViewmarginLeft0}
                                  >
                                 <span style={webStyle.AttendenceviewmainvalueStyle as React.CSSProperties}>3 Points</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row" style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>3<sup>rd</sup> Absence Occurrence</TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.AttendenceViewmarginLeft0}
                                  >
                                 <span style={webStyle.AttendenceviewmainvalueStyle as React.CSSProperties}>5 Points</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                          </TableCell>
                        </TableRow>
                    
                    </TableBody>
                  </Table>
                </TableContainer>

              </>
            </Grid>
          </Grid >
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
    AttendenceviewmainvalueStyle: {
        color: "black",
        marginTop: "10px",
        fontSize: "16px",
        fontFamily:"Open Sans",
        fontWeight: 400,
        },
  AttendenceViewEditbutton: {
    backgroundColor: "FDD100",
    color: "black",
    fontFamily: 'Open Sans',
    padding: "15px 20px 15px 20px",
    width: "200px",
    fontSize: "16px",
    borderRadius: "10px",
    fontWeight: 600,
    textTransform: "none"
  },


  AttendenceViewHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  AttendenceViewHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },

  AttendenceViewmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },

  AttendenceViewlabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  AttendenceViewTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  AttendenceViewblurvalueStyle: {
    color: "black",
    marginTop: "15px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  AttendenceViewrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  AttendenceViewheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },

  informatSubContainerTable: { marginTop: "24px", width: "90%" },
  AttendenceViewinformSecondContainerHead: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
    alignItems: "initial",
    width: "98%"
  },
  AttendenceViewtopdescThirty: {
    marginTop: "20px",
  },
  container: {
    height: "100%",
    width: "100%",
  },


  TableC: {
    fontSize: "14px",
    color: "black",
    fontWeight: 600,
    fontFamily: "Open Sans",
  },

  AttendenceViewmaincontainer: {
    background: "#F3F4F5",
    height: "64px",
    marginRight: "-20px"
  },
  AttendenceViewinformContainer: {
    padding: "0 32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  AttendenceViewinformationSubject: {
    marginTop: "24px",
    width: "800px",
    marginBottom : "30px"
  },
  AttendenceViewleftSide: {
    padding: "19px 0px 21px 38px"
  },
  AttendenceViewnullContainer: {
    padding: "0px 44px 0px 0px"
  },
  AttendenceViewmaintableHead: {
    backgroundColor: "#F5F5F5",
  },
  AttendenceViewlabelStyle10: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    fontWeight: 500,
    color: "black",
    marginBottom: "10px"
  },
  AttendenceViewheadermandatory: {
    display: "flex",
    MarginTop: "30px",
    alignItems: "baseline",
    gap: "4px",
  },
  AttendenceViewtablemainContainer: {
    marginTop: "15px",
    borderRadius: "10px",
    border: "1px solid #979797",
     marginBottom: "10%"
  },
  AttendenceViewinformationSubContainerTable: {
    marginTop: "24px",
    width: "98%",
  },
};
// Customizable Area End
