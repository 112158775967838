import React from "react";

// Customizable Area Start
import ForgotPasswordController, { Props } from "./ForgetPasswordController.web";
import { footerImage, modalImage, newpw, visibility_eye_off_dark, visibility_eye_dark } from "./assets";
import Modal from '@material-ui/core/Modal';
import { Link } from "react-router-dom";

// Customizable Area End

export default class NewPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }
    render() {
        return (
            //Customizable Area Start
            <div style={webStyle.parentDiv} className="registration-login-wrapper">
                <div id="loginBlock" style={webStyle.subDiv} className="sub-div-wrapper">
                    <div className="center-block-header text-center" style={webStyle.headerBlock}>
                        <div className="header-logo">
                            <img src={newpw} alt='logo' style={webStyle.imgStyle} />
                        </div>
                        <div>
                            <h3 style={webStyle.headingText}>Password Reset</h3>
                            <span style={webStyle.subHeadingTitle}>Enter new password</span>
                        </div>
                    </div>

                    <div className="form-block" style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: 'center',
                        marginTop: "15px"
                    }}>
                        <form onSubmit={this.handleNewPasswordSubmit} style={{maxWidth:'386px',width:'100%'}}>
                            <div className="form-field">
                                <span className="label" style={webStyle.inputLabel} >Enter New Password</span>
                                <div className="control" style={{ position: 'relative' }}>
                                    <input
                                        style={{marginBottom:"6px"}}
                                        data-test-id="newpassword"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        className={`control-input new-password-field ${this.state.invalidPasswordMessage ? 'error' : ''}`}
                                        placeholder="Enter Your New Password" name="newPassword" id="password-control"
                                        onChange={(e: any) => { this.handlePasswordChange(e) }}
                                    />
                                    {this.state.showPassword ? (
                                        <img src={visibility_eye_dark} alt='icon-eye' className="icon-eye1" data-test-id="visibility" onClick={() => this.toggleShowPassword(false, this.state.showCPassword)} />

                                    ) : (
                                        <img src={visibility_eye_off_dark} alt='icon-eye' className="icon-eye1" data-test-id="visibilityhidden" onClick={() => this.toggleShowPassword(true, this.state.showCPassword)} />
                                    )}
                                    <span style={{ color: "red" }}>{this.state.invalidPasswordMessage}</span>
                                </div>
                            </div>
                            <div className="form-field">
                                <span className="label" style={webStyle.inputLabel}>Re-Enter New Password</span>
                                <div className="control" style={{ position: 'relative' }}>
                                    <input
                                        style={{marginBottom:"6px"}}
                                        data-test-id="confirmpassword"
                                        type={this.state.showCPassword ? 'text' : 'password'}
                                        className={`control-input new-password-field ${this.state.invalidConfirmPasswordMessage ? 'error' : ''}`}
                                        placeholder="Re-Enter Your New Password" name="confirmNewPassword" id="password-control"
                                        onChange={(e: any) => { this.handlePasswordChange(e) }}
                                    />
                                    {this.state.showCPassword ? (
                                        <img src={visibility_eye_dark} alt='icon-eye' className="icon-eye1" data-test-id="confirmvisibility" onClick={() => this.toggleShowPassword(this.state.showPassword, false)} />

                                    ) : (
                                        <img src={visibility_eye_off_dark} alt='icon-eye' className="icon-eye1" data-test-id="confirmvisibilityhidden" onClick={() => this.toggleShowPassword(this.state.showPassword, true)} />
                                    )}
                                    <span style={{ color: "red" }}>{this.state.invalidConfirmPasswordMessage}</span>

                                </div>
                            </div>

                            <div className="form-field text-center">
                                <div className="control">
                                    <input type="submit" data-test-id="signin" className="button submit" value="Save" />
                                </div>
                            </div>
                        </form>
                        <Modal
                            open={this.state.openModal}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <div style={webStyle.modalContainer} id="modal" className="center-block-header text-center">
                                <div className="header-logo" id="signupBloc2" style={{ marginBottom: '40px' }}>
                                    <img src={modalImage} alt='logo' style={{ height: "164px" }} />
                                </div>
                                <h3 style={webStyle.congratsText} >Congrats!</h3>
                                <p style={webStyle.congratsContent}>Your new password has been saved successfully.</p>
                                <div className="form-field text-center" id="signupBlock4">
                                    <div className="control" id="signupfieldcontrol2">
                                        <div >
                                            <button type="button" style={webStyle.modalBtn} data-test-id="btnmodalcontinue" onClick={() => {this.handleContinue()}}>
                                                Back to Login
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
                <img src={footerImage} alt='logo' style={{ width: "100%", height: "7rem", position: "absolute", bottom: 0, left: 0, right: 0 }} />
            </div>
            //Customizable Area End
        )
    }

}
const webStyle = {
    parentDiv: {
        position: "relative",
        height: "100vh",
        overflowY: "hidden"
    },
    subDiv: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: '100%',
        justifyContent: 'center',
    },
    headerBlock: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: 'center',
        gap: '20px',
    },
    modalContainer: {
        top: "50%",
        left: "50%",
        transform: `translate(-${"50%"}, -${"50%"})`, // Adjust the transform to center the modal
        position: 'absolute',
        width: 534,
        backgroundColor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
        padding: '40px',
        borderRadius: "10px"
    },
    modalBtn: {
        width: "386px",
        height: "56px",
        backgroundColor: "#FFCC00",
        borderRadius: "8px",
        boxShadow: "none",
        border: "none",
        fontSize: '16px',
        lineHeight: '20px',
        color: '#000000'
    },
    congratsText: {
        fontSize: '30px',
        color: '#000',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    congratsContent: {
        fontSize: '20px',
        lineHeight: '22px',
        marginBottom: '40px'
    },
    headingText: {
        fontSize: '30px',
        fontWeight: 'bold',
        color: '#000',
        marginBottom: '20px'
    },
    subHeadingTitle: {
        fontSize: '20px',
        lineHeight: '22px',
    },
    inputLabel: {
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '24px'
    },
    imgStyle: {
        width: "110px"
    }
} as any;