import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from 'moment-timezone';
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  editAdminFormData: any,
  eLeaningData:any,
  userDataLoading: boolean,
  phone: string,
  countryName: string,
  state: any,
  timeZoneFormation: any
  formDataForAdmin: { [key: string]: any };
  profile_img: any;
  profile: any;
  profileUpdated: boolean
  openModal: boolean
  tabNumber:number,
  userType:string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEditEmployeeFormId: string = "";
  getEditAdminId: string = "";
  getEditProfileAdminId: string = "";
  getStatesOfCountriesId: string = "";
  getEditProfileImage: string = "";
  getAdminProfileDetail: string = "";
  getAdminProfileElearningDetail: string = "";
  fileInputRef: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      userDataLoading: false,
      editAdminFormData: [],
      eLeaningData:[],
      formDataForAdmin: {},
      timeZoneFormation: [],
      state: [],
      phone: "",
      countryName: "",
      profile_img: '',
      profile: '', 
      profileUpdated: false,
      openModal:false,
      tabNumber:1,
      userType:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = React.createRef()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {

        switch (apiRequestCallId) {

          case this.getEditAdminId:
            this.setState({ formDataForAdmin: responseJson });
            history.back();
            break;
          case this.getEditEmployeeFormId:
            this.getEditEmployeeFormData(responseJson,false)
            break;
          case this.getStatesOfCountriesId:
            this.getStateResponse(responseJson)
            break;
          case this.getEditProfileAdminId:
            this.getEditEmployeeFormData(responseJson,true)
            break;
          case this.getEditProfileImage:
            this.setState({ profileUpdated: false })
            break;
          case this.getAdminProfileDetail:
            this.getEditEmployeeFormData(responseJson,false)
            break;
          case this.getAdminProfileElearningDetail:
            this.getAdminEleaningData(responseJson)
            break  
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getAdminProfile()
    this.getAdminProfileEleaningData()
    const userType= await getStorageData('loginMessage')
    this.setState({userType:userType})
  }

  calculateAgeOfEmployee = (dateOfBirth: any) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;

  }
  handleClick = (e:any,url:any) => {
    if (!url) {
      e.preventDefault();
    }
  };
  getAdminEleaningData = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ eLeaningData: responseJson.data })
    }
  }
  
  formatTimestamp=(timestamp:any)=> {
    const eventDate:any = new Date(timestamp);
    const now:any = new Date();
    const differenceInMs = now - eventDate;
  
    const differenceInSeconds = differenceInMs / 1000;
    const differenceInMinutes = differenceInSeconds / 60;
    const differenceInHours = differenceInMinutes / 60;
    const differenceInDays = differenceInHours / 24;
    const differenceInWeeks = differenceInDays / 7;
    const differenceInMonths = differenceInDays / 30;
    const differenceInYears = differenceInDays / 365;
  
    if (differenceInHours < 24) {
      const hoursAgo = Math.floor(differenceInHours);
      return hoursAgo === 1 ? `1 hour ago` : `${hoursAgo} hours ago`;
    } else if (differenceInDays < 7) {
      const daysAgo = Math.floor(differenceInDays);
      return daysAgo === 1 ? `1 day ago` : `${daysAgo} days ago`;
    } else if (differenceInWeeks < 4) {
      const weeksAgo = Math.floor(differenceInWeeks);
      return weeksAgo === 1 ? `1 week ago` : `${weeksAgo} weeks ago`;
    } else if (differenceInMonths < 12) {
      const monthsAgo = Math.floor(differenceInMonths);
      return monthsAgo === 1 ? `1 month ago` : `${monthsAgo} months ago`;
    } else {
      const yearsAgo = Math.floor(differenceInYears);
      return yearsAgo === 1 ? `1 year ago` : `${yearsAgo} years ago`;
    }
  }
  getEditEmployeeFormData = (responseJson: any,route:any) => {
    if (responseJson.data) {
      this.setState({
        profile_img: responseJson.data.attributes.image,
        editAdminFormData: responseJson.data,
        userDataLoading: false
      });
      if(route){
        setTimeout(()=>{
          if (window.location.pathname == "/EmployeeProfiles") {
            window.history.pushState({}, "", "/EmployeeProfiles");
            window.location.reload()
          }else{
            window.history.pushState({}, "", "/Dashboard");
            window.location.reload()
          }
        },[1000])
      }
    }
  }
  getStateResponse = (responseJson: any) => {
    if (responseJson && responseJson.state) {

      const stateNamesInEnglish = Object.values(responseJson.state).map((state: any) => state.translations.en);
      this.setState({ state: stateNamesInEnglish })
    }

  }
  handlePhoneNumberChange = (phone: string, countryData: any, formikHandleChange: any, setFieldValue: any) => {

    const timeZones = moment.tz.zonesForCountry(countryData.countryCode);
    const timeZoneFormat = timeZones.map(timezone => {
      const minutes = moment.tz(timezone).utcOffset();
      const hours = Math.abs(Math.floor(minutes / 60));
      const minutesReminder = Math.abs(minutes % 60);
      const Sign = minutes >= 0 ? '+' : '-';

      const abbreviationOfTimeZone = moment.tz(timezone).format('z');


      return `UTC${Sign}${hours.toString().padStart(2, '0')}:${minutesReminder.toString().padStart(2, '0')} (${abbreviationOfTimeZone})`;
    });

    this.setState({ timeZoneFormation: timeZoneFormat })
    formikHandleChange({ target: { value: phone } })
    setFieldValue('full_phone_number', phone)
    setFieldValue('country', countryData.name)

    setFieldValue('zone', timeZoneFormat[0])
    this.getStates(countryData.name)
  }

  handleWorkPhoneNumberChange = (phone: string, formikHandleChange: any, setFieldValue: any) => {

    formikHandleChange({ target: { value: phone } })
    setFieldValue('work_phone', phone)

  }
  FormFieldForAdmin = (formFn: any, touched: any, values: any) => {
    return `form-input ${formFn && touched && !values ? "error" : ""}`
  }

  getAdminProfile = () => {
    let userToken = window.localStorage.getItem("authToken");

    const httpHeader = {
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminProfileDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAdminProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAdminProfileEleaningData = () => {
    let userToken = window.localStorage.getItem("authToken");

    const httpHeader = {
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminProfileElearningDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminProfileElearningDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAdminProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateProfileImage = () => {
    let userToken = window.localStorage.getItem("authToken");

    let formdata = new FormData()

    const httpHeader = {
      token: userToken
    };

    formdata.append('image', this.state.profile, this.state.profile.name);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditProfileImage = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateImageEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateImageAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleAdminFormSubmit = (values: { [key: string]: any }) => {
    if (this.state.profileUpdated) {
      this.updateProfileImage()
    }

    let userToken = window.localStorage.getItem("authToken");

    let formdata = new FormData()

    const httpHeader = {
      token: userToken
    };

    formdata.append("designation", values.designation);
    formdata.append("location", values.city);
    formdata.append("full_phone_number", values.full_phone_number);
    formdata.append("email", values.email);
    formdata.append("country", values.country);
    formdata.append("state", values.state);
    formdata.append("zone", values.zone);
    formdata.append("timings", values.timings);
    formdata.append("first_name", values.first_name);
    formdata.append("last_name", values.last_name);
    formdata.append("preferred_pronounce", values.preferred_pronounce);
    formdata.append("home_town", values.home_town);
    formdata.append("address", values.address);
    formdata.append("blood_group", values.blood_group);
    formdata.append("date_of_birth", values.date_of_birth);
    formdata.append("higher_education", values.higher_education);
    formdata.append("brief_about_employee", values.brief_about_employee);
    formdata.append("ask_employee_about_expertise", values.ask_employee_about_expertise);
    formdata.append("department_name", values.department_name);
    formdata.append("reporting_to", values.reporting_to);
    formdata.append("seating_location", values.seating_location);
    formdata.append("employee_status", values.employee_status);
    formdata.append("date_of_joining", values.date_of_joining);
    formdata.append("work_phone", values.work_phone);
    formdata.append("company_name", values.company_name);
    formdata.append("employee_type", values.employee_type);
    formdata.append("role", values.role);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditProfileAdminId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAdminProfileEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAdminProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  getEditEmployeeDetail = async (id: number) => {
    this.setState({
      userDataLoading: true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditEmployeeFormId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editAdminDetail}/?id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  populateAdminFormData = (values: any, phoneNumberkey: string, phoneValue: any, landlinekey: string, landlineValue: any) => {


    const formData = new FormData();

    for (const key in values) {

      formData.append(key, values[key]);


    }
    if (phoneValue) {
      formData.append(phoneNumberkey, phoneValue);

    }
    if (landlineValue) {
      formData.append(landlinekey, landlineValue);

    }
    return formData;
  };

  getUpdateAdminDetail = async (id: number) => {
    const adminFormData = this.populateAdminFormData(this.state.formDataForAdmin, "full_phone_number", this.state.phone, "work_phone", this.state.phone);

    let userToken = window.localStorage.getItem("authToken");

    const httpHeader = {

      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditAdminId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAdminDataEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      adminFormData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleApiPUTMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getStates = (countryName: any) => {
    const httpsHeaderForStates = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStatesOfCountriesId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStatesendPoint}/?country_name=${countryName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpsHeaderForStates)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleImageUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    this.setState({profile:file, profileUpdated: true })
    reader.onloadend = () => {
      this.setState({ profile_img: reader.result});
    };

    if (file) {
      reader.readAsDataURL(file);
      this.setState({ profileUpdated: true }, () => {
        if (this.state.profileUpdated) {
          this.updateProfileImage();
        }
      });
    }
  };

  handleProfileImageClick = () => {
    this.fileInputRef.current.click();
  };
  // Customizable Area End
}
