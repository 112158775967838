import React from "react";

import {
    Box,
    Typography,
    styled, Paper, Divider, Grid, Button
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../dashboard/src/dashboard.css";
// Customizable Area End

import AddDkrDetailsController, {
    Props,
} from "./AddDkrDetailsController";
import { arrow_left, info_sqaure_bold, info_square, message_dots } from "./assets";


export default class DailySubmissions extends AddDkrDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start

    // Customizable Area End

    render() {
        const { storedLabel, submissions, toggle } = this.state
        const currentDate = new Date();
        return (
            // Customizable Area Start
            <>
                <MainHeaderSection>
                    <img onClick={() => this.backNavigate("ViewKpiDetails")} data-test-id="NavigateBacktoDetails" style={{ height: '14px', width: '9px', cursor: 'pointer' }} src={arrow_left} alt="<" />
                    <KPIdetailsTypography variant="h5" >
                        Daily Submissions</KPIdetailsTypography>
                    <ToggleButton
                        variant="contained"
                        onClick={this.toggleEffect}
                        data-test-id='toggleEffect'
                    >
                        {this.state.toggle ? 'Calendar View' : 'List View'}
                    </ToggleButton>
                </MainHeaderSection>
                {toggle &&
                    <ParentContainer container>
                        <LeftGridSection item sm={12} lg={8}>
                            <LeftBox  >
                                <CalendarBox>
                                    <div className="custom-calender" >
                                        <div className="calendar-container" style={{ position: "relative" }}>
                                            <Calendar
                                                tileContent={({ date }) => {
                                                    const day = date.getDate(); const isToday = date.toDateString() === currentDate.toDateString();
                                                    if (isToday) {
                                                        return null;
                                                    }
                                                    else if (day === 1 || day === 5) {
                                                        return <RedBox ></RedBox>;
                                                    }
                                                    else if (day === 2 || day === 3) {
                                                        return <GreenBox></GreenBox>;
                                                    }
                                                    else {
                                                        return <GrayBox ></GrayBox>;
                                                    }
                                                }}
                                            />
                                            <StyledDivider />
                                        </div>
                                    </div>
                                </CalendarBox>
                            </LeftBox>
                        </LeftGridSection>
                        <RightGridSection item sm={12} lg={4}>
                            <RightBox >
                                <PercentageBox>
                                    <PercentagTypography>Percentage: 50%</PercentagTypography>
                                </PercentageBox>

                                <TodaysSalesBox>
                                    <TodaysSalesChildBox>
                                        <Typography style={{
                                            color: '#000000',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            lineHeight: '22px',
                                            position: 'relative',
                                        }} >How many posters did you do today?
                                            {storedLabel !== "KPI 1" && <> <StyledInfoSquare
                                                src={info_square}
                                                alt="i"
                                            />
                                                <TooltipContainer>
                                                    <TooltipTypography>Standard Allowed Minutes: 15</TooltipTypography>
                                                </TooltipContainer></>}
                                        </Typography>
                                        <Typography style={{
                                            color: '#000000',
                                            fontSize: '16px',
                                            fontWeight: 700,
                                            lineHeight: '22px',
                                        }} >
                                            10,000
                                        </Typography>
                                    </TodaysSalesChildBox>
                                    <RemarkBox>
                                        <RemarkTypography >My Remark</RemarkTypography>
                                        <RemarkTextTypography >
                                            Lorem ipsum dolor sit amet consectetur. Neque lacus id aliquet ipsum.
                                        </RemarkTextTypography>
                                    </RemarkBox>
                                    <ManagersRemarkBox >
                                        <RemarkTypography >Manager's Remark</RemarkTypography>
                                        <RemarkTextTypography color="textSecondary">
                                            Lorem ipsum dolor sit amet consectetur. Neque lacus id aliquet ipsum.
                                        </RemarkTextTypography>
                                    </ManagersRemarkBox>
                                </TodaysSalesBox>
                            </RightBox>
                        </RightGridSection>
                    </ParentContainer>}
                {!toggle &&
                    <SubmissionsSection>
                        <Box>
                            <StyledTitleBox>
                                <StyledDateTypography>Date</StyledDateTypography>
                                <StyledStatusTypography>Status</StyledStatusTypography>
                            </StyledTitleBox>
                            {submissions.map((data, index) => {
                                return (<DateStatusBox key={index}>
                                    <DateTypography>{data.date}</DateTypography>
                                    <StatusBox>
                                        <StyledImageContainer
                                            style={{ filter: 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(1)' }}
                                            src={message_dots}
                                            alt="..."
                                            data-test-id="handleOpen1"
                                        />
                                        <StatusText style={{ color: data.attendance === 'Present' ? ('#00BEA0') : ('#FF5C5C') }}>{data.attendance}</StatusText>
                                    </StatusBox>
                                </DateStatusBox>);
                            })}
                        </Box>
                    </SubmissionsSection>}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const KPIdetailsTypography = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 400,
    color: '#000000',
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'space-between',
});
const MainHeaderSection = styled(Paper)({
    padding: '36px 32px',
    fontFamily: 'Open Sans',
    marginBottom: '24px',
    background: '#F3F4F5',
    display: 'flex',
    alignItems: 'center',
    gap: '21px',
});
const SubmissionsSection = styled(Box)({
    padding: '0 32px'
});
const DateStatusBox = styled(Box)({
    padding: '16px 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    border: '0.5px solid #0000003E',
    boxShadow: '0px 1px 14px 0px #00000011',
    marginBottom: '16px',
    borderRadius: '8px',
    maxWidth: '550px',
    width: '100%',
});
const DateTypography = styled(Box)({
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: '#000000'
})
const StatusBox = styled(Box)({
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    maxWidth: '96px',
    width: '100%'
})
const StyledImageContainer = styled('img')({
    height: '19px',
    width: '21px',
    cursor: "pointer",
});
const StatusText = styled(Typography)({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
})
const StyledDateTypography = styled(Typography)({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#707070',
})
const StyledStatusTypography = styled(Typography)({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#707070',
})
const StyledTitleBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    maxWidth: '550px',
    width: '100%',
    marginBottom: "10px",
    padding: '0 36px 0 50px',
})
const ParentContainer = styled(Grid)({
    padding: '0 50px 0 0',
});
const StyledDivider = styled(Divider)({
    height: '100%',
    top: "0px",
    width: "1.86",
    background: '#486484',
    right: '0px',
    opacity: '21%',
    position: 'absolute',
    '@media (max-width: 1266px)': {
        display: 'none',
    },
})
const TodaysSalesBox = styled(Box)({
    background: '#FFFDF6',
    borderRadius: '8px',
    boxShadow: '0px 1px 14px 0px #00000011',
    border: '0.5px solid #FFCC00',
    padding: '18px',
    borderLeft: '4px solid #FFCC00',
    position: 'relative',
})
const TodaysSalesChildBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '19px',
})
const RemarkBox = styled(Box)({
    background: '#FFFFFF',
    padding: '12px',
    marginBottom: '16px',
})
const RemarkTypography = styled(Box)({
    color: '#000000',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    marginBottom: '8px',
})
const RemarkTextTypography = styled(Box)({
    color: '#000000',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
})
const ManagersRemarkBox = styled(Box)({
    background: '#FFFFFF',
    padding: '12px',
})
const PercentageBox = styled(Box)({
    background: 'linear-gradient(0deg, #FFFDF6 -27.73%, #FFD630 100%, #FFCC00 100%)',
    padding: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    marginBottom: '24px',
})
const PercentagTypography = styled(Typography)({
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21.79px',
    color: '#000000'
})

const StyledInfoSquare = styled('img')({
    marginLeft: '5px',
    top: '3px',
    cursor: 'pointer',
    height: '17px',
    position: 'relative',
    width: '17px',
    transition: '0.3s',
    '&:hover': {
        content: `url(${info_sqaure_bold})`,
        cursor: 'pointer',
        position: 'relative',
    },
    '&:hover + div': {
        display: 'block',
    },
});
const TooltipContainer = styled(Box)({
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    borderRadius: '7px',
    border: '0.5px solid #B8B8B8',
    padding: '8px 10px 10px 8px',
    zIndex: 100,
    left: '70px',
    whiteSpace: 'nowrap',
    display: 'none',
});
const TooltipTypography = styled(Typography)({
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '13.62px',
    color: '#000000'
})
const CalendarBox = styled(Box)({
    '& .custom-calender .react-calendar': {
        width: '100% !important',
        paddingRight: 20
    },
    '& .custom-calender .react-calendar__month-view__weekdays abbr': {
        opacity: '60%',
        fontSize: '20px !important',
        fontWeight: 600,
        lineHeight: '29.96px',
    },
    '& .react-calendar__month-view__days__day': {
        position: 'relative',

    },
    '& .custom-calender .react-calendar__month-view__weekdays': {
        borderBottom: '1px solid #000000',
    },
    '& .react-calendar__navigation__arrow.react-calendar__navigation__next2-button': {
        display: 'none',
    },
    '& .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button': {
        display: 'none',
    },
    '& .react-calendar__tile react-calendar__tile--now react-calendar__year-view__months__month abbr': {
    },
    '& .react-calendar__navigation': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        marginBottom: "0px",
    },
    '& .react-calendar__navigation__arrow.react-calendar__navigation__prev-button': {
        fontSize: '30px',
        cursor: 'pointer',
        opacity: '60%'
    },
    '& .react-calendar__navigation__arrow.react-calendar__navigation__next-button': {
        fontSize: '30px',
        cursor: 'pointer',
        opacity: '60%'
    },
    '& .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend abbr': {
        color: '#88BDFB!important',
    },
    '& .react-calendar': {
        margin: '0 8px'
        ,
    },
    '& .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from': {
        fontWeight: 500,
    },
    '& .react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month abbr': {
        width: '130px',
        borderRadius: '8px',
    },
});
const GreenBox = styled(Box)({
    background: '#FF3A29',
    marginLeft: '5px',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    position: 'absolute',
    right: '22px',
    top: '22px'
})
const RedBox = styled(Box)({
    background: '#34B53A',
    marginLeft: '5px',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    position: 'absolute',
    right: '22px',
    top: '22px'
})
const GrayBox = styled(Box)({
    background: '#C3C4C6',
    marginLeft: '5px',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    position: 'absolute',
    right: '22px',
    top: '22px'
})
const RightBox = styled(Box)({
    maxWidth: '335px',
    '@media (max-width: 1266px)': {
        marginTop: '20px',
    },
})
const LeftBox = styled(Box)({
    position: 'relative',
})
const RightGridSection = styled(Grid)({
    paddingLeft: 58,
    '@media (max-width: 1266px)': {
        display: 'flex',
        justifyContent: 'center',
    },
});

const LeftGridSection = styled(Grid)({
    '@media (max-width: 1266px)': {
        display: 'flex',
        justifyContent: 'center',
    },
});
const ToggleButton = styled(Button)({
   cursor: 'pointer',
})
// Customizable Area End