import React from "react";
// Customizable Area Start
import OtpInput from "react-otp-input";
import Modal from "@material-ui/core/Modal";
import { footerImage, modalImage } from "./assets";
import { visibility_eye_off_dark , visibility_eye_dark } from "../../forgot-password/src/assets";
import { Link } from "react-router-dom";
// Customizable Area End

import EmailAccountRegistrationWebController, {
  Props
} from "./EmailAccountRegistrationController.web";
export default class EmailRegistrationWebBlock extends EmailAccountRegistrationWebController {
  constructor(props: Props) {
    super(props);
  }
  EmailForm = () => (
    <form>
      <div className="form-field" id="signupform">
        <span className="label">Email ID</span>
        <div className="control" id="signupfieldcontrol">
          <input
            type="text"
            data-test-id="txtInputEmail"
            defaultValue={this.state.email}
            onChange={e => {
              this.setEmail(e.target.value);
            }}
            className={`control-input ${this.state.errorMessage ? "error" : ""
              }`}
            placeholder="Enter Your Email ID"
            name="email"
            id="email-control"
            style={{marginBottom:'6px'}}
          />
          <span style={{ color: "red", display: "flex" }}>
            {this.state.errorMessage}
          </span>
        </div>
      </div>

      <div className="form-field text-center" id="signupBlock4">
        <div className="control" id="signupfieldcontrol2">
          <button
            type="button"
            data-test-id="btnEmailLogIn"
            onClick={() => this.sendOtp()}
            className="button submit"
            disabled={this.state.email === undefined || this.state.email === ""}
          >
            Continue
          </button>
        </div>
      </div>
    </form>
  );
  OtpVerification = () => (
    <div className={`form-field otp-block`}>
      <div className="control">
        <OtpInput
          data-test-id="txtInputOtp"
          value={this.state.otp}
          onChange={this.handleOtpChange}
          numInputs={6}
          inputType="number"
          renderSeparator={<span> </span>}
          containerStyle={{ display: 'flex', justifyContent: "space-between" }}
          inputStyle={{ margin: '0 5px', padding: '10px', width: '40px' }}
          renderInput={(props, index) =>
            <input
              data-testid="otpinput"
              id={this.state.otp.length >= (index + 1) ? 'otp-field' : ''}
              autoFocus={index === 0}
              {...props}
            />
          }
        />
      <p
        style={{ alignSelf: "flex-end",display:'flex',justifyContent:"end", fontSize: "14px", color: "#000" }}
      >{`Resend code in 00.${this.formatTimer(this.state.timer)}`}</p>
      <div className="form-field text-center">
        <div className="control">
          <button
            type="button"
            data-test-id="btnEmailVerify"
            onClick={() => this.verifyOtp()}
            className="button submit"
            disabled={this.state.otp === ""}
          >
            Verify
          </button>
        </div>
        {!this.state.isTimerRunning && (
          <div style={webStyle.otpContainer}>
            Didn't received code?{" "}
            <div data-test-id='send-otp' style={{textDecorationLine:"underline", color:"6191C7",marginLeft:"5px"}} onClick={() => this.handleResendClick()}>
              Resend
            </div>
          </div>
        )}
      </div>
    </div>
    </div>

  );
  PasswordForm = () => (
    <form>
      <div className="form-field" id="signupform">
        <span className="label font15">New password</span>
        <div className="control" style={{ position: "relative" }}>
          <input
            type={this.state.showPassword ? "text" : "password"}
            data-test-id="txtInputPassword"
            value={this.state.password}
            onChange={e => {
              this.setPassword(e.target.value);
              if (e.target.value) {
                this.setState({ passworderrorMessage: "" });
              }
            }}
            className={`control-input padd10Radius10 ${
              this.state.passworderrorMessage ? "error" : ""
              }`}
            placeholder="Enter Your Password "
            name="password"
            id="password-control"
          />

          {this.state.showPassword ? (
            <img
              src={visibility_eye_dark}
              alt='icon-eye'
              className="icon-eye"
              data-test-id="confirmvisibility"
              onClick={this.toggleShowPassword}
            />

          ) : (
            <img
              src={visibility_eye_off_dark}
              alt='icon-eye'
              className="icon-eye"
              data-test-id="confirmvisibilityhidden"
              onClick={this.toggleShowPassword}
            />
          )}
        </div>
      </div>
      <div className="form-field marginTop10">
        <span className="label font15">Confirm password</span>
        <div className="control" style={{ position: "relative" }}>
          <input
            style={{marginBottom:'6px'}}
            type={this.state.showConfirmPassword ? "text" : "password"}
            data-test-id="txtInputPassword1"
            value={this.state.confirmPassword}
            onChange={e => {
              this.setConfirmPassword(e.target.value);
              if (e.target.value) {
                this.setState({ confirmPassworderrorMessage: "" });
              }
            }}
            className={`control-input padd10Radius10 ${
              this.state.confirmPassworderrorMessage ? "error" : ""
              }`}
            placeholder="Enter Your Password "
            name="password"
            id="password-control"
          />
           {this.state.showConfirmPassword ? (
            <img
              src={visibility_eye_dark}
              alt='icon-eye'
              className="icon-eye"
              data-test-id="confirmvisibility"
              onClick={this.toggleConfirmShowPassword}
            />

          ) : (
            <img
              src={visibility_eye_off_dark}
              alt='icon-eye'
              className="icon-eye"
              data-test-id="confirmvisibilityhidden"
              onClick={this.toggleConfirmShowPassword}
            />
          )}
          <span data-test-id="error-message" style={{ color: "red",display:"block"}}>
            {this.state.confirmPassworderrorMessage}
          </span>
        </div>
      </div>
      <div className="form-field text-center" id="signupBlock4">
        <div className="control" id="signupfieldcontrol2">
          <button
            type="button"
            data-test-id="btnSavePassword"
            onClick={() => this.setPasswordCall()}
            className="button submit"
            disabled={
              this.state.password === undefined ||
              this.state.confirmPassword === ""
            }
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.parentDiv} id="signupparent">
        <div id="signupBlock" style={webStyle.subDiv}>
          <div id="signupBlock1" className="center-block-header text-center ">
            <div
              className="header-logo"
              id="signupBloc2"
              style={{ marginBottom: "40px" }}
            >
              <img
                src={this.state.logoImage}
                alt="logo"
                style={{
                  width: this.state.header === "Welcome!" ? "171px" : "133px",
                  height: "160px"
                }}
              />
            </div>
            <h3 style={webStyle.welcomeText}>{this.state.header}</h3>
            <p style={webStyle.subHeaderText}>{this.state.subHeader}</p>
          </div>
          <div className="form-block" id="signupBlock3">
            {this.state.emailVerified === 0 && this.EmailForm()}
            {this.state.emailVerified === 1 && this.OtpVerification()}
            {this.state.emailVerified === 2 && this.PasswordForm()}
            <Modal
              open={this.state.openModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div
                style={webStyle.modalContainer}
                id="modal"
                className="center-block-header text-center"
              >
                <div className="header-logo" id="signupBloc2">
                  <img
                    src={modalImage}
                    alt="logo"
                    style={{ height: "140px", marginBottom: "5%" }}
                  />
                </div>
                <h3>Congrats!</h3>
                <p>Your new password has been saved successfully.</p>
                <div className="form-field text-center" id="signupBlock4">
                  <div className="control" id="signupfieldcontrol2">
                    <Link to="SetUserProfile">
                      <button
                        onClick={() =>
                          window.sessionStorage.setItem(
                            "tempToken",
                            this.state.token
                          )
                        }
                        type="button"
                        style={webStyle.modalBtn}
                        data-test-id="btnmodalcontinue"
                      >
                        Continue
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <img
          src={footerImage}
          alt="logo"
          style={{
            width: "100%",
            height: "7rem",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0
          }}
        />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  parentDiv: {
    position: "relative",
    height: "100vh",
    overflowY: "hidden"
  },
  otpContainer: {
    margin: "20px",
    fontSize: "14px",
    color: "#000",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  subDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflow: "auto",
    height: "100%",
    justifyContent: 'center',
  },
  modalContainer: {
    top: "50%",
    left: "50%",
    transform: `translate(-${"50%"}, -${"50%"})`, // Adjust the transform to center the modal
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",
    padding: "16px",
    borderRadius: "10px"
  },
  modalBtn: {
    width: "181px",
    height: "50px",
    backgroundColor: "#FFCC00",
    borderRadius: "5px",
    boxShadow: "none",
    border: "none",
    marginBottom: "10px"
  },
  linkText: {
    textDecoration: "underline",
    color: "#6191C7"
  },
  welcomeText: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#000"
  },
  subHeaderText: {
    fontSize: "20px"
  }
} as any;
// Customizable Area End
