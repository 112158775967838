import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  like, dislike, badge, arrowDown, calendar, employeeId,
  role as roleImg,
  headOffice,
  contactInfo,
  email as emailImg,
  time,
  timezone,
  state as stateImg, country as countryImg
} from "../../dashboard/src/assets";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { editable_icon } from "./assets";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import "../../dashboard/src/dashboard.css"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const validationSchema = {
  role: Yup.string().required("Role is required"),

  full_phone_number: Yup.string().nullable().required("Contact Info is required"),
  country: Yup.string().nullable(),
  address: Yup.string().max(500, "words limit 500"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email ID is required"),
  timings: Yup.string().nullable(),
  zone: Yup.string().nullable(),
  state: Yup.string().nullable(),
  home_town: Yup.string().nullable().min(1, "You need to write characters").required("Hometown is required"),
  blood_group: Yup.string().nullable().required("Blood group is required"),
  higher_education: Yup.string().nullable().min(1, "You need to write atleast 2 characters").required("Education is required"),
  brief_about_employee: Yup.string().max(500, "maximum 500 words are acceptable").required("Brief About Employee is required"),
  ask_employee_about_expertise: Yup.string().max(500, "maximum 500 words are acceptable").required(
    "Ask Employee About/Expertise is required"
  ),
  department_name: Yup.string(),
  reporting_to: Yup.string().nullable(),
  seating_location: Yup.string().nullable(),
  employee_status: Yup.string().nullable(),
  date_of_joining: Yup.string().nullable(),
  work_phone: Yup.string().nullable(),
  company_name: Yup.string().nullable(),
  city: Yup.string(),
  designation: Yup.string(),
  employee_type: Yup.string().nullable()
}


// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController";


export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const currentPath = window.location.pathname;
    const isEditUserPath = currentPath.includes('/CustomisableUserProfiles');
    const disableClass = isEditUserPath ? "labeldisabled" : "";
    const { attributes: {user_type="", employee_id = '', role = "", country = "",work_country_code="" ,country_code="",address = "", full_phone_number = "", email = "", timings = "", zone = "", state = "", first_name = "", last_name = "", preferred_pronounce = "", home_town = "", blood_group = "", date_of_birth = "", higher_education = "", brief_about_employee = "", ask_employee_about_expertise = "", department_name = "", reporting_to = "", seating_location = "", activated = "", date_of_joining = "", work_phone = "", company_name = "", city = "", designation = "", employee_type = this.state.editAdminFormData.attributes?.type || "" } = {} } = this.state.editAdminFormData

    const validationSchemaforAdminProfile = Yup.object().shape({
      ...validationSchema,
      date_of_birth: Yup.string().nullable().test('age', "Employee must be at least 18 years old.", (value) => {

        const age = this.calculateAgeOfEmployee(value);

        if (age < 18) {
          return false;
        }
        return true;
      }).required("Date of Birth is required"),

    });
    const name = localStorage.getItem("adminName")

    const initialFormValues = () => {
      return {
        designation: designation,
        employee_id: employee_id,
        full_phone_number: full_phone_number,
        state: state,
        city: city,
        email: email,
        timings: timings,
        zone: zone,
        first_name: first_name,
        preferred_pronounce: preferred_pronounce,
        country: country,
        home_town: home_town,
        higher_education: higher_education,
        last_name: last_name,
        address: address,
        date_of_birth: date_of_birth,
        blood_group: blood_group,
        brief_about_employee: brief_about_employee,
        date_of_joining: date_of_joining,
        ask_employee_about_expertise: ask_employee_about_expertise,
        reporting_to: reporting_to,
        department_name: department_name,
        seating_location: seating_location,
        employee_status: activated  ? "Active" :  "Inactive",
        company_name: company_name,
        role: role,
        work_phone: work_phone,
        employee_type: employee_type,
        country_code:country_code,
        work_country_code:work_country_code
      }
    }
    return (
      // Customizable Area Start
      <div className="content-wrapper">
        <div className="edit-form-wrapper">
          <Formik
            enableReinitialize
            initialValues={initialFormValues()}

            validationSchema={validationSchemaforAdminProfile}

            onSubmit={values => { this.handleAdminFormSubmit(values) }}
          >
            {({ isSubmitting, errors, touched, resetForm, handleChange, values, handleSubmit, setFieldValue, setFieldTouched }) => (
              <form onSubmit={handleSubmit} className="edit-form form">
                <div className="profile-with-form">
                  <div className="profile-side-wrapper">
                    <div className="profile-details">
                      <div className="profile-icon">
                        <a href="#" className="user-profile" onClick={this.handleProfileImageClick}>
                          <img src={this.state.profile_img as string} alt="user-profile" />
                          <span className="pencil-icon"><img src={editable_icon} alt="pencil" /></span>
                        </a>
                        <input
                          type="file"
                          ref={this.fileInputRef}
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={this.handleImageUpload}
                        />
                      </div>
                      <h3>{values.first_name} {values.last_name}</h3>
                      <p>{values.designation}</p>
                    </div>
                    <div className="achivments-wrapper">
                      <div className="achivments-icon-data">
                        <div className="achivments-icon">
                          <img src={like} alt="thums_up" width={40} height={40} />
                          <div className="point-font">{configJSON.goodPoints}</div>
                        </div>
                        <div className="point-table">- 3</div>
                      </div>
                      <div className="achivments-icon-data">
                        <div className="achivments-icon">
                          <img src={dislike} alt="thums_down" className="perform-icon" width={40} height={40} />
                          <div className="point-font">{configJSON.negativePoints}</div>
                        </div>
                        <div className="point-table">- 1</div>
                      </div>
                      <div className="achivments-icon-data">
                        <div className="achivments-icon">
                          <img src={badge} alt="thums_up" className="perform-icon" width={40} height={40} />
                          <div className="point-font">{configJSON.badgesEarned}</div>
                        </div>
                        <div className="point-table">- 6</div>
                      </div>
                    </div>
                  </div>
                  <div className="step-form">
                    <div className="block-content">
                      <div className="grid-row flex-row">
                        <div className="col-30">
                          <div className="form-group">
                            <div className="field-wrapper">

                              <img src={employeeId} alt="logo" width={30} height={25} className="disableformicon disableformiconEMP" />

                              <label className={disableClass} htmlFor="employee_id">{configJSON.employeeID}</label>

                            </div>

                            <Field
                              type="text"
                              id="employee_id"
                              name="employee_id"
                              disabled={isEditUserPath}
                              onChange={handleChange}
                              className={this.FormFieldForAdmin(errors.employee_id, touched.employee_id, values.employee_id)}
                            />

                            <ErrorMessage
                              name="employee_id"
                              component="div"

                            />
                          </div>
                        </div>
                        <div className="col-40">
                          <div className="form-group">
                            <div className="field-wrapper">
                              <img src={roleImg} alt="logo" width={30} height={25} className="disableformicon disableformiconEMP" />

                              <label className={disableClass} htmlFor="role">{configJSON.designation}</label>
                            </div>
                            <Field
                              type="text"
                              name="designation"
                              id="designation"
                              onChange={handleChange}
                              disabled={isEditUserPath}
                              className={this.FormFieldForAdmin(errors.designation, touched.designation, values.designation)}
                            />

                            <ErrorMessage
                              name="designation"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-30">
                          <div className="form-group">

                            <div className="field-wrapper">

                              <img src={headOffice} alt="logo" width={25} height={25} className="disableformicon disableformiconEMP" />

                              <label className={disableClass} htmlFor="city">{configJSON.location}</label>

                            </div>

                            <Field
                              type="text"
                              name="city"
                              id="city"
                              onChange={handleChange}
                              disabled={isEditUserPath}

                              className={this.FormFieldForAdmin(errors.city, touched.city, values.city)}
                            />

                            <ErrorMessage
                              name="city"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50">
                          <div className="form-group">

                            <div className="field-wrapper">
                              <div>
                                <img src={contactInfo} alt="logo" width={25} height={25} />
                              </div>
                              <div>
                                <label>{configJSON.mobileNumber}</label>
                              </div>
                            </div>

                            <div className="flag-with-input">
                              <div className="flag-input-field" id="phoneInput">

                                <PhoneInput
                                  country={1}
                                  inputProps={{
                                    name: 'full_phone_number',

                                  }}
                                  enableSearch={true}
                                  data-test-id="phoneInput"
                                  value={values.full_phone_number}
                                  onChange={(phone: any, countryData: any) => { this.handlePhoneNumberChange(phone, countryData, handleChange, setFieldValue) }}
                                />
                                <ErrorMessage
                                  name="full_phone_number"
                                  component="div"
                                  className="error-message"
                                />
                              </div>


                            </div>

                          </div>
                        </div>
                        <div className="col-50">
                          <div className="form-group">

                            <div className="field-wrapper">
                              <div>
                                <img src={emailImg} alt="logo" width={25} height={25} className="disableformicon" />
                              </div>
                              <div>
                                <label className={disableClass}>{configJSON.emailID}</label>
                              </div>
                            </div>

                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.email, touched.email, values.email)}
                              name="email"
                              id="email"
                              disabled={isEditUserPath}

                              onChange={handleChange}
                            />

                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50">
                          <div className="form-group">

                            <div className="field-wrapper">
                              <div>
                                <img src={countryImg} alt="logo" width={25} height={25} className="disableformicon" />
                              </div>
                              <div>
                                <label className={disableClass}>{configJSON.country}</label>
                              </div>
                            </div>

                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.country, touched.country, values.country)}
                              name="country"
                              id="country"
                              disabled={isEditUserPath}

                              value={values.country}
                            />

                            <ErrorMessage
                              name="country"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50">
                          <div className="form-group">

                            <div className="field-wrapper">
                              <div>
                                <img src={stateImg} alt="logo" width={25} height={25} className="disableformicon" />
                              </div>
                              <div>
                                <label className={disableClass}>{configJSON.state}</label>
                              </div>
                            </div>

                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.state, touched.state, values.state)}
                              name="state"
                              id="state"
                              disabled={isEditUserPath}

                              value={values.state}
                            />


                            <ErrorMessage
                              name="state"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50">
                          <div className="form-group">

                            <div className="field-wrapper">
                              <div>
                                <img src={timezone} alt="logo" width={25} height={25} className="disableformicon" />
                              </div>
                              <div>
                                <label className={disableClass}>{configJSON.timeZone}</label>
                              </div>
                            </div>
                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.zone, touched.zone, values.zone)}
                              name="zone"
                              data-testid="zone"
                              id="zone"
                              disabled={isEditUserPath}

                              value={values.zone}
                            />

                            <ErrorMessage
                              name="zone"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50">
                          <div className="form-group">
                            <div className="field-wrapper">
                              <div>
                                <img src={time} alt="logo" width={25} height={25} className="disableformicon" />
                              </div>
                              <div>
                                <label className={disableClass}>{configJSON.timings}</label>
                              </div>
                            </div>
                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.timings, touched.timings, values.timings)}
                              name="timings"
                              id="timings"
                              data-testid="timings"
                              disabled={isEditUserPath}

                              value={values.timings}
                            />

                            <ErrorMessage
                              name="timings"
                              component="div"
                              className="error-message"
                            />


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="step-form">
                  <div className="block-title">
                    <h5>{configJSON.basicInfo}</h5>
                  </div>
                  <div className="block-content">
                    <div className="grid-row flex-row">
                      <div className="col-29">
                        <div className="form-group">
                          <label>{configJSON.firstname}</label>

                          <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.first_name, touched.first_name, values.first_name)}
                            name="first_name"
                            id="first_name"
                            onChange={handleChange}

                          />

                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="error-message"
                          />

                        </div>

                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label>{configJSON.lastname}</label>

                          <Field

                            type="text"
                            className={this.FormFieldForAdmin(errors.last_name, touched.last_name, values.last_name)}
                            name="last_name"
                            id="last_name"
                            onChange={handleChange}
                          />

                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="error-message"
                          />

                        </div>
                      </div>
                      <div className="col-37">
                        <div className="form-group">
                          <label>{configJSON.preferredPronoun}</label>

                          <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.preferred_pronounce, touched.preferred_pronounce, values.preferred_pronounce)}
                            name="preferred_pronounce"
                            id="preferred_pronounce"
                            onChange={handleChange}
                          />

                          <ErrorMessage
                            name="preferred_pronounce"
                            component="div"
                            className="error-message"
                          />

                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label>{configJSON.hometown}</label>

                          <Field

                            type="text"
                            className={this.FormFieldForAdmin(errors.home_town, touched.home_town, values.home_town)}
                            name="home_town"
                            id="home_town"
                            onChange={handleChange}

                          />

                          <ErrorMessage
                            name="home_town"
                            component="div"
                            className="error-message"
                          />

                        </div>
                      </div>
                      <div className="col-66">
                        <div className="form-group">
                          <label>{configJSON.address}</label>
                          <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.address, touched.address, values.address)}
                            name="address"
                            id="address"
                            onChange={handleChange}

                          />

                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error-message"
                          />

                        </div>
                      </div>
                      <div className="col-26">
                        <div className="form-group" id="bloodGroup">
                          <label>{configJSON.bloodgroup}</label>
                            <Field
                              as="select"
                              data-testid="bloodGroup"
                              className={this.FormFieldForAdmin(errors.blood_group, touched.blood_group, values.blood_group)}
                              name="blood_group"
                              id="blood_group"
                              onChange={handleChange}
                            >
                              <option id="a+" value="A+">{configJSON.ap}</option>
                              <option id="a-" value="A-">{configJSON.an}</option>
                              <option id="b+" value="B+">{configJSON.bp}</option>
                              <option id="b-" value="B-">{configJSON.bn}</option>
                              <option id="ab+" value="AB+">{configJSON.abp}</option>
                              <option id="ab-" value="AB-">{configJSON.abn}</option>
                              <option id="o+" value="O+">{configJSON.op}</option>
                              <option id="o-" value="O-">{configJSON.on}</option>
                            </Field>
                            <span className="input-group-text blood_group_arrow">
                              <img src={arrowDown} alt="logo" width={25} height={25} />
                            </span>
                          <ErrorMessage
                            name="blood_group"
                            component="div"
                            className="error-message"
                          />


                        </div>
                      </div>
                      <div className="col-30">
                        <div className="form-group">
                          <label>{configJSON.dateOfBirth}</label>
                            <Field
                              type="date"
                              className={this.FormFieldForAdmin(errors.date_of_birth, touched.date_of_birth, values.date_of_birth)}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleChange(e);
                              }}
                              name="date_of_birth"
                              id="date_of_birth"
                            />
                            <span className="input-group-text">
                              <img src={calendar} alt="logo" width={25} height={25} className={errors.date_of_birth && touched.date_of_birth ? "first-image-error" : 'first-image' }/>
                            </span>

                          <ErrorMessage
                            name="date_of_birth"
                            component="div"
                            className="error-message"
                          />

                        </div>
                      </div>
                      <div className="col-44">
                        <div className="form-group" id="higherEducation">
                          <label>{configJSON.highestEducation}</label>
                          <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.higher_education, touched.higher_education, values.higher_education)}
                            name="higher_education"
                            id="higher_education"
                            onChange={handleChange}

                          />

                          <ErrorMessage
                            name="higher_education"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>


                      <div className="col-56">
                        <div className="form-group" data-testId="aboutEmployee">
                          <label>{configJSON.briefAboutEmployee}</label>
                          <textarea
                            rows={4}
                            className={this.FormFieldForAdmin(errors.brief_about_employee, touched.brief_about_employee, values.brief_about_employee)}
                            name="brief_about_employee"
                            id="brief_about_employee"
                            onChange={handleChange}
                            value={values.brief_about_employee}
                          />

                          <ErrorMessage
                            name="brief_about_employee"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col-44">
                        <div className="form-group" id="aboutExpertie">
                          <label>{configJSON.askEmployee}</label>
                          <Field
                            type="text"
                            data-testId="aboutExpertie"
                            className={this.FormFieldForAdmin(errors.ask_employee_about_expertise, touched.ask_employee_about_expertise, values.ask_employee_about_expertise)}
                            name="ask_employee_about_expertise"
                            id="ask_employee_about_expertise"
                            onChange={handleChange}

                          />

                          <ErrorMessage
                            name="ask_employee_about_expertise"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="step-form">
                  <div className="block-title">
                    <h5>{configJSON.work}</h5>
                  </div>
                  <div className="block-content">
                    <div className="grid-row flex-row">
                      <div className="col-29">
                        <div className="form-group" data-testId="department">
                          <label className={disableClass}>{configJSON.department}</label>
                          <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.department_name, touched.department_name, values.department_name)}
                            name="department_name"
                            id="department_name"
                            onChange={handleChange}
                            disabled={isEditUserPath}
                          />

                          <ErrorMessage
                            name="department_name"
                            component="div"
                            className="error-message"
                          />


                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group" id="reportingTo">
                          <label className={disableClass}>{configJSON.reportingTo}</label>
                          <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.reporting_to, touched.reporting_to, values.reporting_to)}
                            name="reporting_to"
                            id="reporting_to"
                            onChange={handleChange}
                            disabled={isEditUserPath}
                          />

                          <ErrorMessage
                            name="reporting_to"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col-37">
                        <div className="form-group" data-testId="seatingLocation">
                          <label className={disableClass}>{configJSON.seatingLocation}</label>
                          <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.seating_location, touched.seating_location, values.seating_location)}
                            name="seating_location"
                            id="seating_location"
                            onChange={handleChange}
                            disabled={isEditUserPath}
                          />

                          <ErrorMessage
                            name="seating_location"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col-37">
                        <div className="form-group" id="employeeStatus">
                          <label className={disableClass}>{configJSON.employeeStatus}</label>
                          <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.employee_status, touched.employee_status, values.employee_status)}
                            name="employee_status"
                            id="employee_status"
                            onChange={handleChange}
                            disabled={isEditUserPath}
                          />

                          <ErrorMessage
                            name="employee_status"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col-29">
                        <div className="form-group" id="joiningDate">
                          <label className={disableClass}>{configJSON.dateOfJoining}</label>
                            <Field
                              type="date"
                              name="date_of_joining"
                              id="date_of_joining"
                              data-testId="joiningDate"
                              className={this.FormFieldForAdmin(errors.date_of_joining, touched.date_of_joining, values.date_of_joining)}
                              onChange={handleChange}
                              disabled={isEditUserPath}
                            />
                            <span className="input-group-text">
                              <img src={calendar} alt="logo" width={25} height={25} className="second-image"/>
                            </span>

                          <ErrorMessage
                            name="date_of_joining"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group" data-testId="landlinePhone">
                          <label className={disableClass}>{configJSON.workPhone}</label>
                          <div className="flag-with-input">
                            <div className="flag-input-field">

                              <PhoneInput
                                country={1}
                                inputProps={{
                                  name: 'work_phone'
                                }}
                                data-test-id="landlinePhoneInput"
                                enableSearch={true}
                                value={values.work_phone}
                                disabled={isEditUserPath}
                                onChange={(phone: any) => { this.handleWorkPhoneNumberChange(phone, handleChange, setFieldValue) }}
                              />
                              <ErrorMessage
                                name="work_phone"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-3">
                        <div className="form-group" id="cName">

                          <label className={disableClass}>{configJSON.companyName}</label>
                          <Field
                            type="text"
                            name="company_name"
                            id="company_name"
                            onChange={handleChange}
                            disabled={isEditUserPath}
                            className={this.FormFieldForAdmin(errors.company_name, touched.company_name, values.company_name)}
                          />

                          <ErrorMessage
                            name="company_name"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>


                      <div className="col-3">
                        <div className="form-group" data-testId="empType">
                          <label className={disableClass}>{configJSON.employeeType}</label>
                          <Field
                            type="text"
                            name="employee_type"
                            id="employee_type"
                            onChange={handleChange}
                            disabled={isEditUserPath}
                            className={this.FormFieldForAdmin(errors.employee_type, touched.employee_type, values.employee_type)}
                          />

                          <ErrorMessage
                            name="employee_type"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group" id="empRole">
                          <label className={disableClass}>{configJSON.role} </label>
                          {this.state.userType === "org_admin" ? ( <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.role, touched.role, values.role)}
                            name="role"
                            id="role"
                            onChange={handleChange}
                            disabled={isEditUserPath}
                            value="Organization Admin"
                          />) : ( <Field
                            type="text"
                            className={this.FormFieldForAdmin(errors.role, touched.role, values.role)}
                            name="role"
                            id="role"
                            onChange={handleChange}
                            disabled={isEditUserPath}
                          /> )}

                          <ErrorMessage
                            name="role"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="step-submit">
                  <div className="control">
                    <button data-testid="formSubmitBtn" type="submit" className="input-submit button"
                      name="save">{configJSON.save}</button>
                    <button data-testid="cancelbutton" type="submit" onClick={() => {resetForm();this.setState({profileUpdated:false})}} className="input-submit button outline" name="cancle">{configJSON.cancel}</button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>


      // Customizable Area End
    );
  }
}


