import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Row {
  id: number;
  title: string;
  description: string;
}
export interface CalculationRow {
  id: string;
  min: number;
  max: number;
  weightage:number
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  typing: string;
  Dropdown: string;
  KpiChange: string;
  rows:any;
  rows2:any
  selectedValues:any;
  options: any,
  addedRows: any;
  nextId:number


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddDkrController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      rows: [
        { id: 1, title: 'Todays Stocks', responseType: 'Manual', options: ['Manual', 'Dropdown'] },
      ],
      rows2: [
        { id: 1, title: 'Todays Stocks', responseType: 'Manual', options: ['Manual', 'Dropdown'] },
      ],
      typing: "Slab",
      Dropdown:"Number",
      KpiChange:"KPI1",
      selectedValues:{},
      options: [],
      addedRows: {},
      nextId:1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }



    // Customizable Area Start
    // Customizable Area End
  navigateToEditTemplate2=()=>{
    this.props.navigation.navigate("EditTemplate2")
  };
  navigateToEditKpi=()=>{
    this.props.navigation.navigate("EditKpi")
  }
  navigateToAddDKR5=()=>{
    this.props.navigation.navigate("AddDkr5")
  }
  navigateToAddDKR=()=>{
    this.props.navigation.navigate("AddDkr")
  }
  handleCalculationTypeChange = (event:any) => {
    this.setState({ typing: event.target.value });
  };
  navigateToVeiwKpi5=()=>{
    this.props.navigation.navigate("ViewKpi5")
  };

handleAddRow = () => {
  const { nextId } = this.state;

  const newRow = {
    id: nextId,
    title: '',
    responseType: 'Manual',
    options: ['Manual', 'Dropdown'], 
  };


  this.setState((prevState) => ({
    rows: [...prevState.rows, newRow],
    selectedValues: { ...prevState.selectedValues, [prevState.rows.length]: newRow.responseType },
    nextId: prevState.nextId + 1,
  }));
};
                             
handleAddOption = (index:any) => {
  this.setState((prevState) => {
    const newAddedRows = { ...prevState.addedRows };
    if (!newAddedRows[index]) {
      newAddedRows[index] = [];
    }
    newAddedRows[index].push({ option: "" });
    return { addedRows: newAddedRows };
  });
};

handleRemoveOption = (index:any, optionIndex:any) => {
  this.setState((prevState) => {
    const newAddedRows = { ...prevState.addedRows };
    newAddedRows[index].splice(optionIndex, 1);
    return { addedRows: newAddedRows };
  });
};

  handleRemoveRow = (index:any) => {
    const { rows } = this.state;
    const updatedRows = rows.filter((row:any, i:any) => i !== index);
    this.setState({
      rows: updatedRows,
    });
  };

  handleSelectChange = (index: any, event: any) => {
    const { selectedValues } = this.state;
    const newSelectedValues = { ...selectedValues, [index]: event.target?.value };
    this.setState({ selectedValues: newSelectedValues });
  };  

  // Customizable Area Start
  // Customizable Area End
}
